$(document).ready(
    function () {

        let p = $("body").attr("p");
        if (p === 'admin_payout_reports') {

            let endDateId = $('#end-date');
            endDateId.change(function () {
                ajaxSubmit();
            });
            ajaxSubmit();

            function ajaxSubmit() {

                let params = {
                    end: endDateId.val()
                };

                $.ajax({
                    type: 'POST',
                    url: "/ajax/admin_payout_reports/search",
                    data: params
                })
                    .done(function (data) {

                        if (data.html !== undefined && data.html !== null) {
                            $("#admin-payout-report-new").remove();
                            $("#payout-report-generate-button").remove();
                            $("#admin-payout-report-datepicker").after(data.html);
                        }
                        $('html,body').scrollTop(0);

                    })
                    .fail(function (data) {

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                    })

            }


        }
    }
);