$(document).ready(
    function () {

        $(document).on("change", "#slider-location-select", function () {

            let location = $(this).find(":selected").val();

            let slider_location_select_ajax_lock = false;

            if (!slider_location_select_ajax_lock) {

                slider_location_select_ajax_lock = true;

                $.ajax({
                    type: 'POST',
                    url: "/ajax/admin_slider/list_location",
                    data: {
                        location: location
                    }
                })
                    .done(function (data) {

                        if (data.html !== undefined && data.html !== null) {
                            $('#slider').remove();
                            $('#slider-header').append(data.html);
                            $('#new-slider-button').attr('href', '/admin/slider/' + location + '/new');
                        }

                    })
                    .fail(function (data) {

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                        slider_location_select_ajax_lock = false;

                    })
                    .always(function () {
                        slider_location_select_ajax_lock = false;
                    });

            }

            return false;


        });

    }
);