$(document).ready(
    function () {

        let p = $("body").attr("p");

        if (p === 'admin_organizers') {

            let urlParams = new URLSearchParams(window.location.search);
            const page = urlParams.get('page');
            getOrganizerList(page);

            $(document).on("click", ".pagination a", function (e) {

                e.preventDefault();
                let page = parseInt($(this).attr("href").substring(6));
                let search_string = $("#search_string").val();
                let status;
                if ($('#status').is(":checked")) {
                    status = $("#status").val();
                } else {
                    status = 1;
                }

                getOrganizerList(page, search_string, status);

            });

        }


        function getOrganizerList(page = 1, search_string = '', status) {
            $.ajax({
                type: 'POST',
                url: "/ajax/admin_organizers/search",
                data: {
                    page,
                    search_string,
                    status
                }
            })
                .done(function (data) {

                    if (data.html !== undefined && data.html !== null) {
                        $("#admin-organizer-list").remove();
                        $(".pagination").remove();
                        $("#admin-organizer-list-search").after(data.html);
                    }
                    $('html,body').scrollTop(0);

                })
                .fail(function (data) {

                    if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                        document.location.href = data.responseJSON.redirect_url;
                    }

                })
        }


    }
);

