$(document).ready(
    function () {

        let p = $("body").attr("p");

        if (p == 'organizer_event_entered_customers' || p == 'organizer_event_subevent_entered_customers') {

            $(document).on("submit", "#ticket_search_form", function () {

                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_event_entered_customers/search",
                    data: {
                        serialized_post: $(this).serialize()
                    }
                })
                    .done(function (data) {

                        if (data.html !== undefined && data.html !== null) {
                            $("#ticket-list").remove();
                            $(".pagination").remove();
                            $("#ticket-list-search").after(data.html);
                        }

                    })
                    .fail(function (data) {

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }
                    });

                return false;

            });

        }

    }
);