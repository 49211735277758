$(document).ready(
    function () {

        let organizer_accept_term_ajax_lock = false;

        $("#organizer-accept-term").on("click", function () {

            if (!organizer_accept_term_ajax_lock) {

                //organizer_accept_term_ajax_lock = true;

                let organizer_id = $(this).data("organizer-id");
                let term_id = $(this).data("term-id");

                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer/accept_term",
                    data: {
                        organizer_id: organizer_id,
                        term_id: term_id
                    }
                })
                    .done(function (data) {

                        if (data.redirect_url !== undefined && data.redirect_url != null) {
                            document.location.href = data.redirect_url;
                        }

                    })
                    .fail(function (data) {

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url != null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }
                        organizer_accept_term_ajax_lock = false;

                    })
                    .always(function () {
                        organizer_accept_term_ajax_lock = false;
                    });

            }

            return false;

        });

    }
);