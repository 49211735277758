$(document).ready(
    function () {

        let p = $("body").attr("p");
        if (p === 'admin_revenue') {

            let startDate = $('#start-date');
            let endDate = $('#end-date');
            let params;

            ajaxSubmitParamsAndGetAdminRevenueFromFee();
            startDate.change(function () {
                ajaxSubmitParamsAndGetAdminRevenueFromFee();
            });
            endDate.change(function () {
                ajaxSubmitParamsAndGetAdminRevenueFromFee();
            });

            function ajaxSubmitParamsAndGetAdminRevenueFromFee() {

                params = {
                    start: startDate.val(),
                    end: endDate.val()
                };

                $.ajax({
                    type: 'POST',
                    url: "/ajax/admin_revenue/search",
                    data: params
                })
                    .done(function (data) {

                        if (data.html !== undefined && data.html !== null) {
                            $("#admin-revenue-from-fee").remove();
                            $("#admin_revenue_from_fee_form").after(data.html);
                        }

                    })
                    .fail(function (data) {

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                    })

            }

        }

    }
);