$(document).ready(
    function () {

        let p = $("body").attr("p");
        if (p === 'admin_fees' || p === 'admin_fees_pos' || p === 'seller_fees' || p === 'seller_fees_pos' || p === 'dealer_fees' || p === 'dealer_fees_pos') {

            $(document).on("focusout", ".fee-level", function () {
                $('.alert').remove();
                let feeValue = parseFloat($(this).val());
                let minimumFee = parseFloat($(this).attr('data-minimum-fee'));
                if (feeValue < minimumFee) {
                    let closestCardBox = $(this).closest('.card-box');
                    let errorMessage = $('.organizer-form').attr('data-minimum-fee-error-message');
                    errorMessage = errorMessage + minimumFee;
                    appendAlert(closestCardBox, errorMessage, 'danger', null, 'form .organizer-form');
                }
            });

            $(document).on("click", ".organizer-fee-save-button", function (e) {

                if ($(this).closest("form.organizer-form").hasClass('has-changed')) {
                    $(this).closest("form.organizer-form").removeClass('has-changed');
                }

                $('.alert').remove();
                let closestCardBox = $(this).closest('.card-box');

                e.preventDefault();
                let organizerId = $(this).data('organizer-id');
                let feeLevel1 = $('#fee-level-1-organizer-id-' + organizerId).val();
                let feeLevel2 = $('#fee-level-2-organizer-id-' + organizerId).val();
                let feeLevel3 = $('#fee-level-3-organizer-id-' + organizerId).val();
                let feeLevel4 = $('#fee-level-4-organizer-id-' + organizerId).val();
                let feeLevel5 = $('#fee-level-5-organizer-id-' + organizerId).val();
                let feeLevel6 = $('#fee-level-6-organizer-id-' + organizerId).val();
                let feeLevel7 = $('#fee-level-7-organizer-id-' + organizerId).val();
                let feePercent = $('#fee-percent-organizer-id-' + organizerId).val();
                let feeOrigin = $('#fee-origin-organizer-id-' + organizerId).val();

                $.ajax({
                    type: 'POST',
                    url: "/ajax/admin_fees/update",
                    data: {
                        organizer_id: organizerId,
                        fee_level_1: feeLevel1,
                        fee_level_2: feeLevel2,
                        fee_level_3: feeLevel3,
                        fee_level_4: feeLevel4,
                        fee_level_5: feeLevel5,
                        fee_level_6: feeLevel6,
                        fee_level_7: feeLevel7,
                        fee_percent: feePercent,
                        fee_origin: feeOrigin
                    }
                })
                    .done(function (data) {

                        if (data === true) {
                            popupAlert();
                        }

                    })
                    .fail(function (data) {

                        if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                            appendAlert(closestCardBox, data.responseJSON.error_message, 'danger', null, 'form .organizer-form');
                        }

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                    })

            });

        }

    }
);