$(document).ready(
    function () {

        let p = $("body").attr("p");

        if (p == 'organizer_event_customers' || p == 'organizer_event_subevent_customers') {

            $(document).on("click", ".ticket-send", function (e) {

                e.preventDefault();
                let ticketId = $(this).attr('data-ticket-send');

                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_event_customers/send_ticket",
                    data: {
                        ticket_id: ticketId
                    }
                })
                    .done(function (data) {

                        if (data.message !== undefined && data.message !== null) {
                            prependAlert('#modal-footer-' + ticketId, data.message, 'success', null, '#ticket-' + ticketId);
                        }

                    })
                    .fail(function (data) {

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                    })

            });

        }

    }
);

