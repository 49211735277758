$(document).ready(
    function () {

        let p = $("body").attr("p");
        if (p == 'organizer_events') {

            $(document).on("click", ".event-refund-btn", function () {
                let event_id = $(this).attr("data-event-id");
                $('#modal-event-refund').modal('show');
                $('#modal-event-refund button#event-refund-modal-btn').val(null)
                $('#modal-event-refund button#event-refund-modal-btn').attr('data-event-id', event_id);
                if (!$('#modal-event-refund div#modal-event-refund-feedback').hasClass('d-none')) {
                    $('#modal-event-refund div#modal-event-refund-feedback').addClass('d-none');
                }
            });

            let event_refund_ajax_lock = false;

            $(document).on("click", "#event-refund-modal-btn", function () {

                let confirmMessage = $(this).attr("data-refund-confirm-message");
                if (!confirm(confirmMessage)) {
                    return false;
                }

                $(this).removeClass('btn-warning');
                $(this).addClass('btn-secondary');
                $(this).prop('disabled', true);
                $('#modal-event-refund input#refund_percent').prop('disabled', true);

                $("#modal-event-refund #number-of-refunded-orders span").html();
                $("#modal-event-refund #number-of-not-refunded-orders-pos span").html();
                $("#modal-event-refund #number-of-refunded-tickets span").html();
                $("#modal-event-refund #refund-event-error-list").empty();
                if (!$("#modal-event-refund #number-of-not-refunded-orders-pos").hasClass('d-none')) {
                    $("#modal-event-refund #number-of-not-refunded-orders-pos").addClass('d-none');
                }
                if (!$("#modal-event-refund #errors").hasClass('d-none')) {
                    $("#modal-event-refund #errors").addClass('d-none');
                }

                if ($('#modal-event-refund div#modal-event-refund-feedback').hasClass('d-none')) {
                    $('#modal-event-refund div#modal-event-refund-feedback').removeClass('d-none');
                }

                let event_id = $(this).attr("data-event-id");
                let refund_percent = $('#modal-event-refund input#refund_percent').val();
                let refund_message = $(this).attr("data-refund-message");

                if (!event_refund_ajax_lock) {

                    event_refund_ajax_lock = true;

                    $.ajax({
                        type: 'POST',
                        url: "/ajax/organizer_event/refund",
                        data: {
                            event_id: event_id,
                            refund_percent: refund_percent
                        }
                    })
                        .done(function (data) {

                            if (data.number_of_refunded_orders !== undefined && data.number_of_refunded_orders !== null) {
                                $("#modal-event-refund #number-of-refunded-orders span").html(data.number_of_refunded_orders);
                            }

                            if (data.number_of_not_refunded_orders_pos !== undefined && data.number_of_not_refunded_orders_pos !== null && data.number_of_not_refunded_orders_pos > 0) {
                                $("#modal-event-refund #number-of-not-refunded-orders-pos").removeClass('d-none');
                                $("#modal-event-refund #number-of-not-refunded-orders-pos span").html(data.number_of_not_refunded_orders_pos);
                            }

                            if (data.number_of_refunded_tickets !== undefined && data.number_of_refunded_tickets !== null) {
                                $("#modal-event-refund #number-of-refunded-tickets span").html(data.number_of_refunded_tickets);
                            }

                            if (data.errors !== undefined && data.errors !== null) {
                                if (data.errors.length === 0) {
                                    $('.event-refund-btn').remove();
                                    $('#event-list ul[data-event-id=' + event_id + '] li.event-name').append(' <span class="refunded">' + refund_message + '</span>');
                                    $('#event-list tr[data-event-id=' + event_id + '] td.event-name').append(' <span class="refunded">' + refund_message + '</span>');

                                } else {

                                    let errors = data.errors;
                                    $("#modal-event-refund #errors").removeClass('d-none');
                                    errors.map(function (value) {
                                        $('#modal-event-refund #refund-event-error-list').append($('<tr>')
                                            .append($('<td>').append(value.name))
                                            .append($('<td>').append(value.order_id))
                                            .append($('<td>').append(value.transaction_id))
                                            .append($('<td>').append(value.error))
                                        );
                                    });

                                }
                            }


                        })
                        .fail(function (data) {

                            if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                                document.location.href = data.responseJSON.redirect_url;
                            }

                            event_refund_ajax_lock = false;

                        })
                        .always(function () {
                            event_refund_ajax_lock = false;
                        });

                }

                return false;

            });

        }

    }
);