$(document).ready(
    function () {

        if ($("#slider").length) {
            let slider = document.getElementById("slider");

            Sortable.create(slider, {
                handle: ".slider-reorder",
                animation: 150,
                sort: true,
                delay: 0,
                touchStartThreshold: 0,
                disabled: false,
                draggable: ".slider",
                direction: "horizontal",
                ghostClass: "sortable-ghost",
                dataIdAttr: "data-slider-id",
                removeCloneOnHide: true,
                store: {
                    set: function (sortable) {
                        let order = sortable.toArray();
                        ajaxListOrder(order)
                    }
                }
            });

        }

        function ajaxListOrder($order) {

            let location = $('#slider-location-select').find(":selected").val();

            $.ajax({
                type: 'POST',
                url: "/ajax/admin_slider/list_sort",
                data: {
                    list_sort: JSON.stringify($order),
                    location: location
                }
            })
                .done(function (data) {

                    if (data === true) {
                        popupAlert();
                    }

                })
                .fail(function (data) {

                    if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                        document.location.href = data.responseJSON.redirect_url;
                    }

                });

        }

    }
);
