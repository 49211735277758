$(document).ready(
    function () {


        let p = $("body").attr("p");
        if (p === 'organizer_settings') {

            $("#open-organizer-change-password-form-modal-button").on("click", function (e) {

                if ($("#organizer-change-password-modal").length) {
                    $("#organizer-change-password-modal").remove();
                }
                let organizer_id = $(this).data("organizer-id");
                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer/get_change_password_modal",
                    data: {
                        organizer_id: organizer_id
                    }
                })
                    .done(function (data) {
                        if (data.html !== undefined && data.html != null) {
                            $("#organizer_settings_form").after(data.html);
                            $('#organizer-change-password-modal').modal('show');
                        }
                    })
                    .fail(function (data) {

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url != null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                    });
                return false;
            });

        }

    }
);