$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p === 'admin_article' && a == 'list') {

            $(document).on("click", ".delete-article", function () {
                let selectedArticleId = $(this).attr("data-article-id");
                let confirmText = $(this).attr("data-delete-text");
                let confirmDeleteArticle = confirm(confirmText);
                if (confirmDeleteArticle) {
                    deleteArticle(selectedArticleId);
                }
                return false;
            });

            function deleteArticle(articleId) {
                $.ajax({
                    type: 'POST',
                    url: "/ajax/admin_article/delete",
                    data: {
                        article_id: articleId
                    }
                })
                    .done(function (data) {
                        if (data === true) {
                            $('#article-id-' + articleId).remove();
                            popupAlert();
                        }
                    })
                    .fail(function (data) {
                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }
                    });
            }

        }

    }
);