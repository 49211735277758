$(document).ready(
    function () {

        let p = $("body").attr("p");
        if (p === 'dealer_sales' || p==='admin_sales') {

            let seller_sales_ajax_lock = false;

            $(document).on("click", ".seller-sales-data", function () {

                if ($("#seller-sales-data-modal").length) {
                    $("#seller-sales-data-modal").remove();
                }

                let sellerId = $(this).attr('data-seller-id');
                let dealerId = $(this).attr('data-dealer-id');
                let month = $('#month').val();
                let year = $('#year').val();
                let salesDateRevenueType=$('#sales_data_revenue_type option:selected').val();
                let startDate = $('#start_date').val();
                let endDate = $('#end_date').val();

                if (!seller_sales_ajax_lock) {

                    seller_sales_ajax_lock = true;

                    $.ajax({
                        type: 'POST',
                        url: "/ajax/organizer/get_sales_data",
                        data: {
                            seller_id: sellerId,
                            dealer_id: dealerId,
                            month: month,
                            year: year,
                            start_date: startDate,
                            end_date: endDate,
                            sales_data_revenue_type:salesDateRevenueType
                        }
                    })
                        .done(function (data) {

                            if (data.html !== undefined && data.html != null) {
                                $("#content").after(data.html);
                                $('#seller-sales-data-modal').modal('show');
                            }

                        })
                        .fail(function (data) {

                            if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                                document.location.href = data.responseJSON.redirect_url;
                            }

                            seller_sales_ajax_lock = false;

                        })
                        .always(function () {
                            seller_sales_ajax_lock = false;
                        });

                }

                return false;

            });

        }

    }
);