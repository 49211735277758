$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p === 'organizer_area_zone' && a == 'new') {

            let area_zone_registration_ajax_lock = false;

            $(document).on("submit", "#area-zone-registration-form", function () {

                $(':disabled').each(function () {
                    $(this).removeAttr('disabled');
                });

                let formSeats = $("#area-zone-registration-form circle");
                let seats = createSeatArray(formSeats);
                let seatsInput = $("<input type='hidden' name='seats'/>");
                let seatArray = [];
                $.each(seats, function (index, value) {
                    seatArray.push(JSON.stringify(value));
                });
                seatsInput.val(seatArray);
                $(this).append(seatsInput);

                let area_id = $("#area_id").val();

                if (!area_zone_registration_ajax_lock) {

                    area_zone_registration_ajax_lock = true;

                    let formData = new FormData(document.getElementById('area-zone-registration-form'));

                    $.ajax({
                        type: 'POST',
                        url: "/ajax/organizer_area_zone/new",
                        data: {
                            serialized_post: $(this).serialize()
                        }
                    })
                        .done(function (data) {

                            if (data.message !== undefined && data.message !== null) {

                                prependAlert('#area-zone-registration-button-wrapper', data.message, 'success', null, '#area-zone-registration-form');
                                $('#area_registration_button').hide();

                                setTimeout(function () {
                                    document.location.href = "/organizer/area/" + area_id + "/zones";
                                }, 3000);

                            }

                        })
                        .fail(function (data) {

                            if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                                document.location.href = data.responseJSON.redirect_url;
                            }

                            if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                                prependAlert('#area-zone-registration-button-wrapper', data.responseJSON.error_message, 'danger', null, '#area-zone-registration-form');
                            }

                            area_zone_registration_ajax_lock = false;

                        })
                        .always(function () {
                            area_zone_registration_ajax_lock = false;
                        });

                }

                return false;

            });

            function createSeatArray(formSeats) {
                let m, seatArray = [];
                for (m = 0; m < formSeats.length; m++) {
                    seatArray.push({
                        "id": formSeats[m].id,
                        "coordinate_x": $("#" + formSeats[m].id).attr("cx"),
                        "coordinate_y": $("#" + formSeats[m].id).attr("cy"),
                        "row_info": $("#" + formSeats[m].id).attr("row_info"),
                        "seat_info": $("#" + formSeats[m].id).attr("seat_info"),
                        "rating": $("#" + formSeats[m].id).attr("rating"),
                        "status": $("#" + formSeats[m].id).attr("status"),
                    });
                }
                return seatArray;
            }

        }

    }
);