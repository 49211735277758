$(document).ready(
    function () {

        $(document).on("submit", "#event_subevent_deviation_register_form", function (e) {

            e.preventDefault();

            $.ajax({
                type: 'POST',
                url: "/ajax/organizer_event_subevent/new",
                data: {
                    serialized_post: $(this).serialize()
                }
            })
                .done(function (data) {

                    if (data == true) {
                        popupAlert();
                        setTimeout(function () {
                            document.location.reload();
                        }, 1000);
                    }

                })
                .fail(function (data) {

                    if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                        document.location.href = data.responseJSON.redirect_url;
                    }

                    if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                        prependAlert('#div_event_subevent_deviation_button_wrapper', data.responseJSON.error_message, 'danger', null, '#event_subevent_deviation_register_form')
                    }

                });

        });


    }
);