$(document).ready(
    function () {

        $(document).on("click", '.config-option-features-button', function () {
            if ($('#config-option-features-modal').length) {
                $('#config-option-features-modal').modal('show');
                return false;
            }
            $.ajax({
                type: 'POST',
                url: "/ajax/config_option/show",
                data: {}
            })
                .done(function (data) {
                    if (data.html !== undefined && data.html !== null) {
                        $('#content').append(data.html);
                        $('#config-option-features-modal').modal('show');
                    }
                })
                .fail(function (data) {
                    if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                        document.location.href = data.responseJSON.redirect_url;
                    }
                })
            return false;
        });


    });