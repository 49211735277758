$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p == 'event' && a == 'show') {


            $('#event-subevent-calendar').change(function () {

                let organizer_id = $("body").attr("organizer_id");
                let event_id = $("body").attr("event_id");
                let start = $(this).val();

                $.ajax({
                    type: 'POST',
                    url: "/ajax/event/get_subevents",
                    data: {
                        organizer_id: organizer_id,
                        event_id: event_id,
                        start: start
                    }
                })
                    .done(function (data) {

                        addDateCookie(start)
                        $("#event-subevent-list").remove();
                        $("#event-subevent-calendar-wrapper").after(data.html);

                    })
                    .fail(function (data) {

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url != null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                    })

            });

            function addDateCookie(date) {
                let daysForCookieToExpire = 1;
                let cookieExpires = new Date(new Date().getTime() + daysForCookieToExpire * 1000 * 60 * 60 * 24);
                document.cookie = "event_subevent_start = " + date + "; expires=" + cookieExpires + "; path=/";
            }

            function removeDateCookie(date) {
                let daysForCookieToExpire = 0;
                let cookieExpires = new Date(new Date().getTime() + daysForCookieToExpire * 1000 * 60 * 60 * 24);
                document.cookie = "event_subevent_start =; expires=" + cookieExpires + "; path=/";
            }

        }

    }
);




