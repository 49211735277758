$(document).ready(
    function () {

        let p = $("body").attr("p");
        if (p === 'organizer_payout_reports') {

            let payoutReportWithZeroAmountId = $('#payout-report-with-zero-amount');
            let payout_sum_is_also_zero;
            payoutReportWithZeroAmountId.change(function () {
                payout_sum_is_also_zero = payoutReportWithZeroAmountId.val();
                if (payoutReportWithZeroAmountId.prop("checked") === false) {
                    payout_sum_is_also_zero = null;
                }
                ajaxSubmit();
            });

            function ajaxSubmit() {

                let params = {
                    payout_sum_is_also_zero: payout_sum_is_also_zero
                };

                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_payout_reports/search",
                    data: params
                })
                    .done(function (data) {

                        if (data.html !== undefined && data.html !== null) {
                            $("#payout-report-list").remove();
                            $(".pagination").remove();
                            $("#payout-report-with-zero-amount-div").after(data.html);
                        }
                        $('html,body').scrollTop(0);

                    })
                    .fail(function (data) {

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                    })

            }


        }
    }
);