$(document).ready(
    function () {

        $(document).on("click", ".area-delete", function () {

            let area_id = $(this).data("area-id");
            let confirm_message = $(this).data("confirm-message");

            if (!confirm(confirm_message)) {
                return false;
            }

            $.ajax({
                type: 'POST',
                url: "/ajax/organizer_area/delete",
                data: {
                    area_id: area_id
                }
            })
                .done(function (data) {

                    if (data === true) {
                        popupAlert();
                        setTimeout(function () {
                            document.location.href = "/organizer/area";
                        }, 1500);
                    }

                })
                .fail(function (data) {

                    if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                        document.location.href = data.responseJSON.redirect_url;
                    }

                });

            return false;

        });

    }
);