$(document).ready(
    function () {

        $(document).on("click", ".admin-remove", function () {

            let confirm_message = $("#confirm-admin-remove").attr("data-confirm-message");
            let user_id = $(this).attr("data-user-id");

            if (!confirm(confirm_message)) {
                return false;
            }

            $.ajax({
                type: 'POST',
                url: "/ajax/admin_settings_users/remove",
                data: {
                    user_id: user_id
                }
            })
                .done(function (data) {

                    if (data === true) {

                        $("#user-" + user_id).fadeOut(1000);

                        setTimeout(function () {
                            $("#user-" + user_id).remove();
                        }, 1000);

                    }

                })
                .fail(function (data) {

                    if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                        document.location.href = data.responseJSON.redirect_url;
                    }

                    if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                        inserAfterAlertTr('#user-' + user_id, data.responseJSON.error_message, 'danger');
                    }

                });

            return false;

        });

    }
);