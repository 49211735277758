$(document).ready(
    function () {

        $(document).on("click", ".slider-delete", function () {
            let selectedSliderId = $(this).attr("data-delete-id");
            let confirmText = $(this).attr("data-delete-text");
            let confirmDeleteSlider = confirm(confirmText);
            if (confirmDeleteSlider) {
                deleteSlider(selectedSliderId);
            }
            return false;
        });

        function deleteSlider(sliderId) {
            $.ajax({
                type: 'POST',
                url: "/ajax/admin_slider/delete",
                data: {
                    slider_id: sliderId
                }
            })
                .done(function (data) {

                    if (data === true) {
                        $('#slider-id-' + sliderId).remove();
                        popupAlert();
                    }

                })
                .fail(function (data) {

                    console.log(data.responseJSON);

                    if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                        document.location.href = data.responseJSON.redirect_url;
                    }

                    if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                        prependAlert('#div_slider_register_button', data.responseJSON.error_message, 'danger', null, '#slider-registration-form-' + sliderId)
                    }

                })
        }

    }
);