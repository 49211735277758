$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p == 'user' && a == 'set_password') {

            let user_set_password_ajax_lock = false;

            $(document).on("submit", "#user_set_password_form", function () {

                if (!user_set_password_ajax_lock) {

                    user_set_password_ajax_lock = true;

                    $.ajax({
                        type: 'POST',
                        url: "/ajax/user/set_password",
                        data: {
                            serialized_post: $(this).serialize()
                        }
                    })
                        .done(function (data) {

                            if (data.isAdmin === true) {
                                document.location.href = "/admin/dashboard";
                            } else if (data.isOrganizer === true) {
                                document.location.href = "/organizer";
                            } else if (data.isSeller === true) {
                                document.location.href = "/seller/sales";
                            } else if (data.isDealer === true) {
                                document.location.href = "/dealer/sales";
                            } else if (data.isCustomer === true) {
                                document.location.href = "/customer_articles";
                            }else {
                                $("#user_set_password_form").remove();
                                $("#user_set_password_form_title").remove();
                                if ($("#user_set_password_success_title").hasClass('d-none')){
                                    $("#user_set_password_success_title").removeClass('d-none')
                                }
                                if ($("#user_set_password_success").hasClass('d-none')){
                                    $("#user_set_password_success").removeClass('d-none')
                                }

                            }

                        })
                        .fail(function (data) {

                            if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                                prependAlert('#div_set_password_button', data.responseJSON.error_message, 'danger', null, '#user_set_password_form')
                            }

                            user_set_password_ajax_lock = false;

                        })
                        .always(function () {
                            user_set_password_ajax_lock = false;
                        });

                }

                return false;

            });

        }

    }
);