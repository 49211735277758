$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p === 'visit_tracker_location' && a === 'new') {

            let visit_tracker_location_new_ajax_lock = false;

            $(document).on("submit", "#visit_tracker_location_registration_form", function (e) {

                if (!visit_tracker_location_new_ajax_lock) {

                    visit_tracker_location_new_ajax_lock = true;

                    $.ajax({
                        type: 'POST',
                        url: "/ajax/visit_tracker_location/new",
                        data: {
                            serialized_post: $(this).serialize()
                        }
                    })
                        .done(function (data) {

                            if (data.message !== undefined && data.message !== null) {
                                prependAlert('#visit-location-tracker-register-button-wrapper', data.message, 'success', null, '#visit_tracker_location_registration_form');
                            }
                            setTimeout(function () {
                                document.location.href = "/organizer/location";
                            }, 1500);

                        })
                        .fail(function (data) {

                            if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                                document.location.href = data.responseJSON.redirect_url;
                            }

                            if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                                prependAlert('#visit-location-tracker-register-button-wrapper', data.responseJSON.error_message, 'danger', null, '#visit_tracker_location_registration_form');
                            }

                            visit_tracker_location_new_ajax_lock = false;

                        })
                        .always(function () {
                            visit_tracker_location_new_ajax_lock = false;
                        });

                }

                return false;

            });

        }

    }
);