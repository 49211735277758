$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p === 'organizer_event_ticket_category' && a == 'list') {

            $(document).on("click", ".event-ticket-category-delete", function () {

                let selected_event_ticket_category = $(this).attr("data-delete-id");

                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_event_ticket_category/delete",
                    data: {
                        event_ticket_category_id: selected_event_ticket_category
                    }
                })
                    .done(function (data) {

                        if (data.message !== undefined && data.message !== null) {

                            let confirmAlert = confirm(data.message);
                            if (confirmAlert === true) {
                                unPublishEventTicketCategory(selected_event_ticket_category);
                            }

                        } else if (data === true) {
                            $('#event-ticket-category-id-' + selected_event_ticket_category).remove();
                            popupAlert();
                        }

                    })
                    .fail(function (data) {

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                    })

                return false;

            });


            function unPublishEventTicketCategory(selected_event_ticket_category) {
                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_event_ticket_category/unpublish",
                    data: {
                        event_ticket_category_id: selected_event_ticket_category
                    }
                })
                    .done(function (data) {

                        if (data === true) {

                            if ($("#event-ticket-category-id-publish-btn-" + selected_event_ticket_category).length == 0) {

                                let publishButton = $('<button id="event-ticket-category-id-publish-btn-' + selected_event_ticket_category + '" class="btn btn-primary event-ticket-category-publish" data-publish-id="' + selected_event_ticket_category + '">');
                                $('#event-ticket-category-buttons-' + selected_event_ticket_category).prepend(publishButton);

                                let publishButtonText = $('#event-ticket-category-buttons-' + selected_event_ticket_category).attr('data-publish-button-text');
                                $('#event-ticket-category-id-publish-btn-' + selected_event_ticket_category).html(publishButtonText);
                            }

                            popupAlert();
                        }

                    })

            }

        }

    }
);