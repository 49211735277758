$(document).ready(
    function () {

        if (window.cookieconsent !== null && window.cookieconsent !== undefined) {
            window.cookieconsent.initialise({
                container: document.getElementById("content"),
                palette: {
                    "popup": {
                        background: "#edeff5",
                        text: "#838391"
                    },
                    button: {
                        "background": "#4b81e8"
                    }
                },
                theme: "classic",
                content: {
                    message: "Denne nettsiden bruker cookies som er nødvendig for at du skal kunne bestille eller logge inn, og for at vi skal kunne gi deg den beste servicen. ",
                    dismiss: "Skjønner",
                    link: "Les mer",
                    href: "privacy"
                }
            });
        }

    }
);

