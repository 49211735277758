$(document).ready(
    function () {

        let p = $("body").attr("p");
        if (p === 'admin_organizers' || p === 'dealer_organizers' || p === 'seller_organizers') {
            $(document).on("click", ".config-option-event-form-switch", function (e) {
                let organizerId = $(this).attr('data-organizer-id');
                if ($(this).is(":checked") && $('#config-option-event-form-answer-wrapper-' + organizerId).hasClass('d-none')) {
                    $('#config-option-event-form-answer-wrapper-' + organizerId).removeClass('d-none');
                }
                if (!$(this).is(":checked")) {
                    $('#event_form_answer' + organizerId).prop("checked", false);
                    if (!$('#config-option-event-form-answer-wrapper-' + organizerId).hasClass('d-none')) {
                        $('#config-option-event-form-answer-wrapper-' + organizerId).addClass('d-none');
                    }
                }
            });
        }
    }
);