$(document).ready(
    function () {

        let p = $("body").attr("p");
        if (p === 'admin_fees_visit_tracker' || p === 'seller_fees_visit_tracker' || p === 'dealer_fees_visit_tracker') {

            $(document).on("click", ".organizer-fee-visit-tracker-save-button", function (e) {

                if ($(this).closest("form.organizer-form").hasClass('has-changed')) {
                    $(this).closest("form.organizer-form").removeClass('has-changed');
                }

                $('.alert').remove();
                let closestCardBox = $(this).closest('.card-box');

                e.preventDefault();
                let organizerId = $(this).data('organizer-id');
                let feeMainVisitTracker = $('#fee-main-visit-tracker-organizer-id-' + organizerId).val();
                let feeExtraVisitTracker = $('#fee-extra-visit-tracker-organizer-id-' + organizerId).val();

                $.ajax({
                    type: 'POST',
                    url: "/ajax/admin_fees_visit_tracker/update",
                    data: {
                        organizer_id: organizerId,
                        fee_main_visit_tracker: feeMainVisitTracker,
                        fee_extra_visit_tracker: feeExtraVisitTracker
                    }
                })
                    .done(function (data) {

                        if (data === true) {
                            popupAlert();
                        }

                    })
                    .fail(function (data) {

                        if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                            appendAlert(closestCardBox, data.responseJSON.error_message, 'danger', null, 'form .organizer-form');
                        }

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                    })

            });

        }

    }
);