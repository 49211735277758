$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p === 'organizer_area_zone' && a === 'list') {

            $(document).on("click", ".area-zone-status", function () {
                let areaZoneStatusButton = $(this);
                let areaZoneId = $(this).attr('data-area-zone-id');
                let areaZoneStatus = $(this).attr('data-area-zone-status');

                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_area_zone/publish",
                    data: {
                        area_zone_id: areaZoneId,
                        status: areaZoneStatus
                    }
                })
                    .done(function (data) {

                        if (data.status !== undefined && data.status !== null) {
                            popupAlert();
                            areaZoneStatusButton.attr("data-area-zone-status", data.status);
                            areaZoneStatusButton.html(data.button_text);

                        }

                    })
                    .fail(function (data) {

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }


                    });


            });


        }

    });