$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p === 'event' && a === 'show') {
            let searchParams = new URLSearchParams(window.location.search);
            if (searchParams.has('continue') && parseInt(searchParams.get('continue')) === 1) {
                $('html, body').animate({
                    scrollTop: $("#order_register_form").offset().top
                }, 1000);
            }
        }

    });