$(document).ready(
    function () {

        let order_ajax_lock = false;

        $(document).on("submit", "#order_register_form", function (e) {

            e.preventDefault();

            if (!order_ajax_lock) {

                order_ajax_lock = true;

                let formData = new FormData(document.getElementById('order_register_form'));

                $.ajax({
                    type: 'POST',
                    url: "/ajax/order/new",
                    data: formData,
                    async: true,
                    cache: false,
                    processData: false,
                    contentType: false
                })

                    .done(function (data) {

                        if (data.payment_redirect_url !== undefined && data.payment_redirect_url !== null) {
                            document.location.href = data.payment_redirect_url;
                        }

                        if (data.redirect_url !== undefined && data.redirect_url !== null) {
                            cancelTicketReservation(data.redirect_url)
                        }

                        if (data.confirmation_code !== undefined && data.confirmation_code !== null) {
                            document.location.href = "/order/confirmation/" + data.confirmation_code;
                        }

                    })
                    .fail(function (data) {

                        if (data.responseJSON !== undefined && data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                            prependAlert('#div_order_buttons', data.responseJSON.error_message, 'danger', null, '#order_register_form')
                        }

                        order_ajax_lock = false;

                    })
                    .always(function () {
                        order_ajax_lock = false;
                    });

            }

        });


        function cancelTicketReservation(redirect_url) {
            $.ajax({
                type: 'GET',
                url: "/ajax/order/cancel_reservation"
            })
                .done(function (data) {
                    document.location.href = redirect_url;
                })
        }

    }
);
