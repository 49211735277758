$(document).ready(
    function () {

        $(document).on("submit", "#subevent_search_form", function () {

            $.ajax({
                type: 'POST',
                url: "/ajax/organizer_event/search_subevent",
                data: {
                    serialized_post: $(this).serialize()
                }
            })

                .done(function (data) {

                    if (data.html !== undefined && data.html !== null) {
                        $("#subevent-list").remove();
                        $("#subevent-search-form-wrapper").after(data.html);
                    }

                })
                .fail(function (data) {

                    if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                        document.location.href = data.responseJSON.redirect_url;
                    }

                });

            return false;

        });

        $("#subevent_search_form").submit();

    }
);