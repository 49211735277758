$(document).ready(
    function () {

        $(document).on("click", ".slider-update", function () {

            let selected_slider = $(this).attr("data-update-id");

            let slider_update_ajax_lock = false;

            $(document).on("submit", "#slider-registration-form-" + selected_slider, function () {

                if (!slider_update_ajax_lock) {

                    slider_update_ajax_lock = true;

                    let formData = new FormData(document.getElementById('slider-registration-form-' + selected_slider));

                    $.ajax({
                        type: 'POST',
                        url: "/ajax/admin_slider/update",
                        data: formData,
                        async: true,
                        cache: false,
                        processData: false,
                        contentType: false
                    })
                        .done(function (data) {

                            if (data === true) {
                                popupAlert();
                            }

                            if (data.new_image !== undefined && data.new_image !== null) {
                                $("#slider-image-" + selected_slider).attr('src', data.new_image);
                                popupAlert();
                            }

                        })
                        .fail(function (data) {

                            if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                                document.location.href = data.responseJSON.redirect_url;
                            }

                            if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                                prependAlert('#div_slider_register_button', data.responseJSON.error_message, 'danger', null, '#slider-registration-form-' + selected_slider)
                            }

                            slider_update_ajax_lock = false;

                        })
                        .always(function () {
                            slider_update_ajax_lock = false;
                        });

                }

                return false;

            });

        });

    }
);