$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p === 'visit_tracker_location' && a === 'edit') {

            $(document).on("click", "#status", function (e) {

                let visitTrackerLocationId = $("#visit_tracker_location_id").val();
                let status = $(this).attr('data-status');

                $.ajax({
                    type: 'POST',
                    url: "/ajax/visit_tracker_location/edit",
                    data: {
                        visit_tracker_location_id: visitTrackerLocationId,
                        status: status
                    }
                })
                    .done(function (data) {

                        if (data.message !== undefined && data.message !== null) {
                            if (status == 1) {
                                $("#status").attr('data-status', '0');
                                $("#status").html($("#status").attr('data-deactivate'));
                            } else {
                                $("#status").attr('data-status', '1');
                                $("#status").html($("#status").attr('data-activate'));
                            }
                            popupAlert();
                        }


                    })
                    .fail(function (data) {

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                    });


                return false;

            });

        }

    }
);