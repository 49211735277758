$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p === 'visit_tracker_visitor' && a === 'new') {

            let visit_tracker_visitor_new_ajax_lock = false;

            $(document).on("submit", "#visit-tracker-visitor-registration-form", function (e) {

                if (!visit_tracker_visitor_new_ajax_lock) {

                    visit_tracker_visitor_new_ajax_lock = true;

                    $.ajax({
                        type: 'POST',
                        url: "/ajax/visit_tracker_visitor/new",
                        data: {
                            serialized_post: $(this).serialize()
                        }
                    })
                        .done(function (data) {

                            if (data.message !== undefined && data.message !== null) {
                                prependAlert('#visit-tracker-visitor-register-button-wrapper', data.message, 'success', null, '#visit-tracker-visitor-registration-form');
                                $('#visit-tracker-visitor-register-button-wrapper button').addClass('d-none');
                                setTimeout(function () {
                                    document.getElementById("visit-tracker-visitor-registration-form").reset();
                                    $('#visit-tracker-visitor-register-button-wrapper button').removeClass('d-none');
                                    $('div.alert').remove();
                                }, 4000);
                            }

                        })
                        .fail(function (data) {

                            if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                                document.location.href = data.responseJSON.redirect_url;
                            }

                            if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                                prependAlert('#visit-tracker-visitor-register-button-wrapper', data.responseJSON.error_message, 'danger', null, '#visit-tracker-visitor-registration-form')
                            }

                            visit_tracker_visitor_new_ajax_lock = false;

                        })
                        .always(function () {
                            visit_tracker_visitor_new_ajax_lock = false;
                        });

                }

                return false;

            });

        }

    }
);