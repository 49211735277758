$(document).ready(
    function () {

        let p = $("body").attr("p");
        if (p === 'admin_payout_reports') {

            let startDateId = $('#start-date');
            let endDateId = $('#end-date');

            $(document).on("click", "#payout-report-generate-button", function () {
                let params = {
                    start: startDateId.val(),
                    end: endDateId.val()
                };

                let confirm_message = $(this).data("confirm-message");
                if (!confirm(confirm_message)) {
                    return false;
                }

                ajaxSubmitParamsAndGetAdminOrganizerPayoutInformation(params);
                return false;
            });

            function ajaxSubmitParamsAndGetAdminOrganizerPayoutInformation(params) {

                $.ajax({
                    type: 'POST',
                    url: "/ajax/admin_payout_reports/new",
                    data: params
                })
                    .done(function (data) {

                        if (data === true) {
                            popupAlert();
                            $("#admin-payout-report-new").remove();
                            payoutReportButton.hide();
                            setTimeout(function () {
                                document.location.href = "/admin/payout/previous";
                            }, 1500);
                        }

                    })
                    .fail(function (data) {

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                    })

            }


        }
    }
);