$(document).ready(
    function () {


        let p = $("body").attr("p");
        if (p === 'organizer_settings') {

            let organizer_change_password_ajax_lock = false;

            $(document).on("submit", "#organizer-change-password-form", function () {

                    if ($('.alert').length) {
                        $('.alert').remove();
                    }
                    if (!organizer_change_password_ajax_lock) {
                        organizer_change_password_ajax_lock = true;
                        $.ajax({
                            type: 'POST',
                            url: "/ajax/organizer/change_password",
                            data: {
                                serialized_post: $(this).serialize()
                            }
                        })
                            .done(function (data) {

                                if (data.message !== undefined && data.message != null) {
                                    $('#organizer-change-password-field-wrapper').remove();
                                    $('#organizer-change-password-button').remove();
                                    prependAlert('#organizer-change-password-button-wrapper', data.message, 'success', null, '#organizer-change-password-form');
                                }

                            })
                            .fail(function (data) {

                                if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url != null) {
                                    document.location.href = data.responseJSON.redirect_url;
                                }

                                if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                                    prependAlert('#organizer-change-password-button-wrapper', data.responseJSON.error_message, 'danger', null, '#organizer-change-password-form')
                                }
                                organizer_change_password_ajax_lock = false;
                            });
                    }
                    return false;
                }
            );

        }

    }
);