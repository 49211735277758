$(document).ready(
    function () {

        let user_new_password_ajax_lock = false;

        $(document).on("submit", "#user_new_password_form", function () {

            if (!user_new_password_ajax_lock) {

                user_new_password_ajax_lock = true;

                $.ajax({
                    type: 'POST',
                    url: "/ajax/user/new_password",
                    data: {
                        serialized_post: $(this).serialize()
                    }
                })
                    .done(function (data) {

                        if (data.message !== undefined && data.message !== null) {
                            prependAlert('#div_new_password_button', data.message, 'success', null, '#user_new_password_form')
                        }

                    })
                    .fail(function (data) {

                        if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                            prependAlert('#div_new_password_button', data.responseJSON.error_message, 'danger', null, '#user_new_password_form')
                        }

                        user_new_password_ajax_lock = false;

                    })
                    .always(function () {
                        user_new_password_ajax_lock = false;
                    });

            }

            return false;

        });

    }
);