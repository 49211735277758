$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p === 'visit_tracker_visitor' && a === 'list') {

            $(document).on("click", ".pagination a", function (e) {

                e.preventDefault();

                let page = parseInt($(this).attr("href").substring(6));
                let search_string = $("#search_string").val();
                let visit_tracker_location_id = $("#visit_tracker_location_id").val();
                let start_date = $("#start_date").val();
                let start_time = $("#start_time").val();
                let end_date = $("#end_date").val();
                let end_time = $("#end_time").val();

                getEventCustomerList(visit_tracker_location_id, page, search_string, start_date, start_time, end_date, end_time);

            });

            function getEventCustomerList(visit_tracker_location_id = null, page = 1, search_string = '', start_date = '', start_time = '', end_date = '', end_time = '') {

                $.ajax({
                    type: 'POST',
                    url: "/ajax/visit_tracker_visitor/search",
                    data: {
                        visit_tracker_location_id,
                        page,
                        search_string,
                        start_date,
                        start_time,
                        end_date,
                        end_time
                    }
                })
                    .done(function (data) {

                        if (data.html !== undefined && data.html !== null) {
                            $("#visit-tracker-visitor-list").empty();
                            $('#visit-tracker-visitor-list').append(data.html);
                        }

                        $('html,body').scrollTop(0);

                    })
                    .fail(function (data) {

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                    })
            }

        }

    });