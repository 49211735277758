$(document).ready(
    function () {

        $(document).on("click", "#new-subevent-tab", function () {
            $('#new-subevent').tab('show');
        });

        $(document).on("click", "#deviation-tab", function () {
            $('#deviation').tab('show');
        });

        $('[data-toggle="tooltip"]').tooltip();

    }
);