$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p === 'admin_article' && (a === 'new' || a === 'update')) {

            if ($('.tinymce').length > 0) {

                let language = $('body').attr('lang');
                let vendorCss = '/public/static/' + $('body').data('vendor-css');
                let tinyMceVariables = {
                    mode: "specific_textareas",
                    editor_selector: "mceEditor",
                    selector: '#article-tinymce',
                    content_style: ".mce-content-body {font-size:14px;}",
                    plugins: "table,code,hr,link,image, imagetools ,searchreplace,visualblocks,fullscreen,lists ",
                    toolbar: 'formatselect fontsizeselect | bold italic strikethrough forecolor backcolor | link image  | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat ',
                    fontsize_formats: '12px 14px 16px 18px 20px 22px 24px 26px 28px 30px 32px 36px 44px 50px',
                    image_advtab: true,
                    imagetools_cors_hosts: ['dev.fticket.no', 'fticket.no'],
                    valid_children: "+body[style]",
                    convert_urls: false,
                    relative_urls: false,
                    branding: false,
                    content_css: vendorCss
                };

                if (language === 'no') {
                    tinyMceVariables.language = 'nb_NO';
                }

                tinymce.init(tinyMceVariables);

            }

        }

    }
);

