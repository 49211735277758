$(document).ready(
    function () {

        let p = $("body").attr("p");
        if (p === 'admin_organizers' || p === 'dealer_organizers') {

            function deactivateOrganizer(organizer_id, status) {
                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_settings/deactivate",
                    data: {
                        organizer_id,
                        status
                    }
                })
                    .done(function (data) {
                        if (data.message !== undefined && data.message !== null) {
                            prependAlert('#modal-footer-' + organizer_id, data.message, 'success', null, '#modal-organizer-' + organizer_id)
                        }
                    })
                    .fail(function (data) {
                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }
                    })
            }

            function reactivateOrganizer(organizer_id, status) {
                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_settings/update",
                    data: {
                        organizer_id,
                        status
                    }
                })
                    .done(function (data) {
                        if (data.message !== undefined && data.message !== null) {
                            prependAlert('#modal-footer-' + organizer_id, data.message, 'success', null, '#modal-organizer-' + organizer_id)
                        }
                    })
                    .fail(function (data) {
                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }
                    })
            }

            $(document).on("click", ".organizer-deactivate", function (e) {

                e.preventDefault();
                let organizer_id = $(this).attr("data-deactivate-organizer-id");
                let status = $(this).attr("data-status");
                if (!$(this).hasClass('d-none')) {
                    $(this).addClass('d-none')
                }
                if ($('[data-reactivate-organizer-id=' + organizer_id + ']').hasClass('d-none')) {
                    $('[data-reactivate-organizer-id=' + organizer_id + ']').removeClass('d-none')
                }
                $('#alert-warning-'+organizer_id).remove();
                deactivateOrganizer(organizer_id, status);

            });

            $(document).on("click", ".organizer-reactivate", function (e) {

                e.preventDefault();
                let organizerId = $(this).attr("data-reactivate-organizer-id");
                let status = $(this).attr("data-status");
                if (!$(this).hasClass('d-none')) {
                    $(this).addClass('d-none')
                }
                if ($('[data-deactivate-organizer-id=' + organizerId + ']').hasClass('d-none')) {
                    $('[data-deactivate-organizer-id=' + organizerId + ']').removeClass('d-none')
                }
                reactivateOrganizer(organizerId, status);

            });

        }

    }
);