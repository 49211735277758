$(document).ready(
    function () {

        let p = $("body").attr("p");
        if (p === 'admin_organizers' || p === 'dealer_organizers' || p === 'seller_organizers') {

            let admin_organizer_setting_ajax_lock = false;

            $(document).on("click", ".admin-organizer-settings", function (e) {

                e.preventDefault();

                $('#admin-organizer-setting-modal').remove();

                if (!admin_organizer_setting_ajax_lock) {

                    admin_organizer_setting_ajax_lock = true;

                    let organizerId = $(this).data('organizer-id');

                    $.ajax({
                        type: 'POST',
                        url: "/ajax/admin_organizers/get_organizer_settings",
                        data: {
                            organizer_id: organizerId
                        }
                    })
                        .done(function (data) {

                            if (data.html !== undefined && data.html !== null) {
                                $("#admin-organizer-list").after(data.html);
                                $('#admin-organizer-setting-modal').modal('show');
                            }


                        })
                        .fail(function (data) {

                            if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                                document.location.href = data.responseJSON.redirect_url;
                            }

                            admin_organizer_setting_ajax_lock = false;

                        })
                        .always(function () {
                            admin_organizer_setting_ajax_lock = false;
                        });


                }
            });


        }

    }
);