$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p == 'organizer_event_form' && a == 'edit') {

            $(document).on("click ", '#add-event-form-age-button', function () {
                let event_id = $("body").attr("event_id");
                let addQuestionButton = $(this);
                let event_form_question_index = parseInt($(this).attr('data-event-form-question-index')) + 1;
                let age = true;
                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_event_form/add_question",
                    data: {
                        event_id,
                        event_form_question_index,
                        age
                    }
                }).done(function (data) {
                    if (data.html !== undefined && data.html !== null) {
                        addQuestionButton.attr('data-event-form-question-index', event_form_question_index);
                        $("#add-event-form-question-option-button").attr('data-event-form-question-index', event_form_question_index);
                        $("#add-event-form-country-button").attr('data-event-form-question-index', event_form_question_index);
                        if (addQuestionButton.parent('div').siblings('div#event-form-question-form').children('div.event-form-question').length) {
                            addQuestionButton.parent('div').siblings('div#event-form-question-form').children('div.event-form-question').last().after(data.html);
                            $('html, body').animate({
                                scrollTop: addQuestionButton.parent('div').siblings('div#event-form-question-form').children('div.event-form-question').last().offset().top
                            }, 1000);
                        } else {
                            $('#event-form-question-form').prepend(data.html);
                        }
                    }
                }).fail(function (data) {
                    if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                        document.location.href = data.responseJSON.redirect_url;
                    }
                });
            });
        }
    });