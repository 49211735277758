$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if ((p == 'organizer_area_zone_seat' && a == 'show') || (p == 'organizer_area_zone_version' && a == 'new')) {

            if (p == 'organizer_area_zone_version' && a == 'new' && $('#seat-information').hasClass('d-none') && !$('#standing_zone').is(':checked')) {
                $('#seat-information').removeClass('d-none');
            }

            let areaId = $("body").attr("area_id");
            let areaZoneId = $("body").attr("area_zone_id");
            let areaZoneVersionId = null;
            if (typeof $("body").attr("area_zone_version_id") !== typeof undefined && $("body").attr("area_zone_version_id") !== false) {
                areaZoneVersionId = $("body").attr("area_zone_version_id");
            }
            let seatCoordinates, maxSeatsPerRow, maxRows;

            const sceneWrapperHeight = $("#scene-wrapper").outerHeight();
            const seatsHeight = $("#scene-wrapper  #seats").outerHeight();

            getAreaZoneSeats(areaId, areaZoneId);

            function getAreaZoneSeats(areaId, areaZoneId) {

                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_area_zone_seat/show",
                    data: {
                        area_id: areaId,
                        area_zone_id: areaZoneId,
                        area_zone_version_id: areaZoneVersionId
                    }
                })
                    .done(function (data) {

                        if (data !== undefined && data !== null) {
                            seatCoordinates = JSON.parse(data.seat_coordinates);
                            maxSeatsPerRow = data.max_seat_per_row;
                            maxRows = data.max_rows;
                            setSceneHeight(maxRows);
                            generateSeats(seatCoordinates, maxSeatsPerRow);
                            updateSeatStatusColor();
                        }
                    })
                    .fail(function (data) {

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                    });

            }

            function updateSeatStatusColor() {
                let seats = $("#scene-wrapper circle");
                $.each(seats, function () {
                    if ($(this).attr("status") == 0) {
                        $(this).attr("style", "fill:#ebebeb");
                    } else {
                        let rating = $(this).attr('rating');
                        let color = generateColor(rating * 1);
                        $(this).attr("style", "fill:" + color);
                    }
                });
            }

            function generateColor(rating) {
                let color;
                switch (rating) {
                    case 1:
                        color = "#28a745";
                        break;
                    case 2:
                        color = "#007bff";
                        break;
                    case 3:
                        color = "#dc3545";
                        break;
                    default:
                        color = "#28a745";
                }
                return color;
            }


            function setSceneHeight(maxRows) {
                if (maxRows > 7) {
                    $("#scene-wrapper").css("height", 200 + (30 * maxRows));
                    $("#scene-wrapper #seats").css("height", 100 + (30 * maxRows));
                } else {
                    $("#scene-wrapper").css("height", sceneWrapperHeight);
                    $("#scene-wrapper #seats").css("height", seatsHeight);
                }
            }


            function generateSeats(seatCoordinates, maxSeatsPerRow = 0) {

                let svgContainer = d3.select("#seats").append("svg").attr("width", Math.max(((maxSeatsPerRow * 30) - 10), 0) + "px").attr("height", "100%").attr("class", "generated-seats");
                let circles = svgContainer.selectAll("circle").data(seatCoordinates).enter().append("circle");

                let circleAttributes = circles.attr("cx", function (d) {
                    return d.coordinate_x;
                }).attr("cy", function (d) {
                    return d.coordinate_y;
                }).attr("r", function (d) {
                    return d.radius;
                }).style("fill", function (d) {
                    return d.color;
                }).attr("id", function (d) {
                    return d.id;
                }).attr("rating", function (d) {
                    return d.rating;
                }).attr("status", function (d) {
                    return d.status;
                }).attr("row_info", function (d) {
                    return d.row_info;
                }).attr("seat_info", function (d) {
                    return d.seat_info;
                }).attr("class", function (d) {
                    return d.class;
                });

            }


        }

    });