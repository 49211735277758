$(document).ready(
    function () {
        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p === 'visit_tracker_location' && (a === 'new' || a === 'edit')) {
            $(document).on("click", "#required_note", function () {
                if ($(this).is(":checked") && $('#label_text').hasClass('d-none')) {
                    $('#label_text').removeClass('d-none');
                }
                if ($(this).is(":checked") && $('#label_property_wrapper').hasClass('d-none')) {
                    $('#label_property_wrapper').removeClass('d-none');
                }
                if (!$(this).is(":checked")) {
                    $('#label_text').val('');
                    $('#label_text').addClass('d-none');
                    $('#label_property_wrapper').addClass('d-none');
                }
            });

            $(document).on("click", "#label_property_checkbox", function () {
                if ($(this).is(":checked") && $('#label_property_option_wrapper').hasClass('d-none')) {
                    $('#label_property_option_wrapper').removeClass('d-none');
                } else {
                    $('#label_property_option_wrapper').addClass('d-none');
                }
            });

            $(document).on("click", "#add-extra-label-property-option-button", function () {
                $.ajax({
                    type: 'GET',
                    url: "/ajax/visit_tracker_location/add_answer",

                })
                    .done(function (data) {
                        if (data.html !== undefined && data.html !== null) {
                            $('#add-extra-label-property-option-button-wrapper').before(data.html);
                        }
                    })
                    .fail(function (data) {
                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }
                    });
            });

            $(document).on("click", ".remove-extra-label-property-option-button", function () {
                $(this).closest('.row').remove();
            });

        }
    });