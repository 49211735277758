$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if ((p == 'organizer_area_zone' || p == 'organizer_area_zone_version') && a == 'new') {

            const sceneWrapperHeight = $("#scene-wrapper").outerHeight();
            const seatsHeight = $("#scene-wrapper  #seats").outerHeight();

            $(document).on("click", "#generate-area-zone-seat-button", function () {

                if ($(".generated-seats").length) {
                    let confirmMessage = $(this).data("confirm-new-seats-message");
                    if (!confirm(confirmMessage)) {
                        return false;
                    }
                }

                let maxRows = $("#max_rows").val();
                let maxRowsSpecification = $("#max_rows_specification").val();
                let maxSeatsPerRow = $("#max_seats_per_row").val();
                let maxSeatsPerRowSpecification = $("#max_seats_per_row_specification").val();

                if (maxRows > 7) {
                    $("#scene-wrapper").css("height", 200 + (30 * maxRows));
                    $("#scene-wrapper #seats").css("height", 100 + (30 * maxRows));
                } else {
                    $("#scene-wrapper").css("height", sceneWrapperHeight);
                    $("#scene-wrapper #seats").css("height", seatsHeight);
                }

                if (isNaN(maxRows) || maxRows === "") {
                    let missingMaxRowsMessage = $(this).data("missing-max-rows-message");
                    prependAlert('#generate-area-zone-seat-button-wrapper', missingMaxRowsMessage, 'danger', null, '#area-zone-registration-form');
                    return;
                }
                if (!(isNaN(maxRows) || maxRows === "") && (isNaN(maxSeatsPerRow) || maxSeatsPerRow === "")) {
                    let missingMaxSeatsPerRowMessage = $(this).data("missing-max-seats-per-row-message");
                    prependAlert('#generate-area-zone-seat-button-wrapper', missingMaxSeatsPerRowMessage, 'danger', null, '#area-zone-registration-form');
                    return;
                }
                $("svg.generated-seats").remove();
                let seatCoordinates = generateSeatCoordinates(maxRows, maxRowsSpecification, maxSeatsPerRow, maxSeatsPerRowSpecification);
                generateSeats(seatCoordinates, maxSeatsPerRow);

                if ($("#seat-information").hasClass("d-none")) {
                    $("#seat-information").removeClass("d-none")
                }

                $("#people_limit").val(maxRows * maxSeatsPerRow);

            });

            $(document).on("click", ".generated-seat", function () {
                const seat = $(this);
                if (seat.hasClass('clicked')) {
                    seat.removeClass('clicked');
                    openSeatModal(seat);
                } else {
                    seat.addClass('clicked');
                    setTimeout(function () {
                        if (seat.hasClass('clicked')) {
                            seat.removeClass('clicked');
                            if (seatRatingTool) {
                                changeSeatRating(seat, seatRatingTool);
                            } else {
                                changeSeatStatus(seat);
                            }
                        }
                    }, 250);
                }

            });

            function openSeatModal(seat) {
                $("#modal-area-zone-seat .alert").remove();
                $("#modal-area-zone-seat #seat_id").val(seat.attr("id"));
                $("#modal-area-zone-seat #row_info").val(seat.attr("row_info"));
                $("#modal-area-zone-seat #row_info").attr("placeholder", seat.attr("row_info"));
                $("#modal-area-zone-seat #seat_info").val(seat.attr("seat_info"));
                $("#modal-area-zone-seat #seat_info").attr("placeholder", seat.attr("seat_info"));
                $("#modal-area-zone-seat input[name=rating][value=" + seat.attr("rating") + "]").prop('checked', true);
                $("#modal-area-zone-seat").modal('show');
            }

            function changeSeatStatus(seat) {
                if (seat.attr("status") == 1) {
                    seat.attr("status", 0);
                    seat.attr("style", "fill:#ebebeb");
                    updatePeopleLimit('subtract');
                } else {
                    let rating = seat.attr('rating');
                    let color = generateColor(rating * 1);
                    seat.attr("status", 1);
                    seat.attr("style", "fill:" + color);
                    updatePeopleLimit('add');
                }
            }

            function updatePeopleLimit(operator) {
                let peopleLimit = $('#people_limit').val() * 1;
                if (operator == 'add') {
                    peopleLimit = peopleLimit + 1;
                }
                if (operator == 'subtract') {
                    peopleLimit = peopleLimit - 1;
                }
                $('#people_limit').val(peopleLimit);
            }

            function changeSeatRating(seat, rating) {
                let color = generateColor(rating * 1);
                seat.attr("status", 1);
                seat.attr("rating", rating);
                seat.attr("style", "fill:" + color);
            }

            function generateName(length, specification) {
                if (specification !== "letter") {
                    return generateNumberSpecification(length);
                } else {
                    return generateLetterSpecification(length);
                }
            }

            function generateNumberSpecification(length) {
                let k, numbers = [];
                for (k = 1; k <= length; k++) {
                    numbers.push(k);
                }
                return numbers;
            }

            function generateLetterSpecification(length) {
                let alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZÆØÅ'.split('');
                if (length <= alphabet.length) {
                    return alphabet.slice(0, length);
                } else {
                    let l, letters = [];
                    let alphabetIndex = 0;
                    let alphabetRounds = 0;
                    for (l = 0; l <= length; l++) {
                        if (l % alphabet.length === 0) {
                            alphabetRounds++;
                            alphabetIndex = 0;
                        }
                        if (alphabetRounds > 0) {
                            letters.push(alphabet[alphabetRounds] + "" + alphabet[alphabetIndex]);
                        } else {
                            letters.push(alphabet[alphabetIndex]);
                        }
                        alphabetIndex++;
                    }
                    return letters;
                }
            }

            function generateRating(i, j, maxRows, maxSeatsPerRow) {
                let ratingX = 1, ratingY = 1, seatDiameter = 30, spaceBetweenSeats = 10;
                let zoneWidth = (maxSeatsPerRow * seatDiameter) + ((maxSeatsPerRow - 1) * spaceBetweenSeats);
                let zoneHeight = (maxRows * seatDiameter) + ((maxRows - 1) * spaceBetweenSeats);
                let zoneMiddleSpotWidth = zoneWidth / 2;
                let zoneMiddleSpotHeight = zoneHeight / 2;
                let seatDistanceFromMiddleSpotWidth = zoneMiddleSpotWidth - ((j * seatDiameter) + ((j) * spaceBetweenSeats) + (seatDiameter / 2));
                if (seatDistanceFromMiddleSpotWidth < 0) {
                    Math.abs(seatDistanceFromMiddleSpotWidth - seatDiameter)
                }
                let seatDistanceFromMiddleSpotHeight = Math.abs(zoneMiddleSpotHeight - ((i * seatDiameter) + ((i) * spaceBetweenSeats) + (seatDiameter / 2)));
                if (seatDistanceFromMiddleSpotHeight < 0) {
                    Math.abs(seatDistanceFromMiddleSpotHeight - seatDiameter)
                }
                let seatTotalDistanceFromMiddleSpot = Math.sqrt((seatDistanceFromMiddleSpotWidth * seatDistanceFromMiddleSpotWidth) + (seatDistanceFromMiddleSpotHeight * seatDistanceFromMiddleSpotHeight));
                if (seatTotalDistanceFromMiddleSpot > (zoneMiddleSpotWidth * 75 / 100)) {
                    ratingX = 2;
                }
                if (seatTotalDistanceFromMiddleSpot > (zoneMiddleSpotHeight * 75 / 100)) {
                    ratingY = 2;
                }
                if (seatTotalDistanceFromMiddleSpot > zoneMiddleSpotWidth) {
                    ratingX = 3;
                }
                if (seatTotalDistanceFromMiddleSpot > zoneMiddleSpotHeight) {
                    ratingY = 3;
                }
                return Math.round((ratingX + ratingY) / 2);
            }

            function generateColor(rating) {
                let color;
                switch (rating) {
                    case 0:
                        color = "#ebebeb";
                        break;
                    case 1:
                        color = "#28a745";
                        break;
                    case 2:
                        color = "#007bff";
                        break;
                    case 3:
                        color = "#dc3545";
                        break;
                    default:
                        color = "#28a745";
                }
                return color;
            }

            function generateSeatCoordinates(maxRows, maxRowsSpecification, maxSeatsPerRow, maxSeatsPerRowSpecification) {

                let i, j, seat, jsonCircles = [];
                let rowName = generateName(maxRows, maxRowsSpecification);
                let seatName = generateName(maxSeatsPerRow, maxSeatsPerRowSpecification);

                for (i = 0; i < maxRows; i++) {

                    for (j = 0; j < maxSeatsPerRow; j++) {

                        let rating = 1;
                        if ($('#autogenerate_rating').prop("checked") == true) {
                            rating = generateRating(i, j, maxRows, maxSeatsPerRow);
                        }
                        let color = generateColor(rating);

                        seat = {
                            "coordinate_y": ((maxRows * 30) + 10) - (30 * i),
                            "coordinate_x": 10 + (30 * j),
                            "row_info": rowName.slice(i, i + 1),
                            "seat_info": seatName.slice(j, j + 1),
                            "rating": rating,
                            "status": 1,
                            "radius": 10,
                            "color": color,
                            "class": "generated-seat",
                            "id": rowName.slice(i, i + 1) + "_" + seatName.slice(j, j + 1)
                        };
                        jsonCircles.push(seat);
                    }
                }
                return jsonCircles;
            }


            function generateSeats(seatCoordinates, maxSeatsPerRow) {

                let sceneWrapperWidth = $("#scene-wrapper").width();
                let seatWrapperWidth = ((maxSeatsPerRow * 30) - 10);
                if (seatWrapperWidth > sceneWrapperWidth) {
                    seatWrapperWidth = "100%";
                } else {
                    seatWrapperWidth = seatWrapperWidth + "px";
                }

                let svgContainer = d3.select("#seats")
                    .append("svg")
                    .attr("width", seatWrapperWidth)
                    .attr("height", "100%")
                    .attr("class", "generated-seats")
                    .call(d3.zoom().on("zoom", function () {
                        svgContainer.attr("transform", d3.event.transform)
                    }).scaleExtent([1, 4]))
                    .append("g");

                svgContainer.call(d3.zoom())
                    .on("mousedown.zoom", null)
                    .on("touchstart.zoom", null)
                    .on("touchend.zoom", null);

                let circles = svgContainer.selectAll("circle").data(seatCoordinates).enter().append("circle");

                let circleAttributes = circles.attr("cx", function (d) {
                    return d.coordinate_x;
                }).attr("cy", function (d) {
                    return d.coordinate_y;
                }).attr("r", function (d) {
                    return d.radius;
                }).style("fill", function (d) {
                    return d.color;
                }).attr("id", function (d) {
                    return d.id;
                }).attr("rating", function (d) {
                    return d.rating;
                }).attr("status", function (d) {
                    return d.status;
                }).attr("row_info", function (d) {
                    return d.row_info;
                }).attr("seat_info", function (d) {
                    return d.seat_info;
                }).attr("class", function (d) {
                    return d.class;
                });

            }

            $(document).on("click", "#edit-area-zone-seat-button", function () {

                let seatId = $("#modal-area-zone-seat #seat_id").val();
                let rowInfo = $("#modal-area-zone-seat #row_info").val();
                let seatInfo = $("#modal-area-zone-seat #seat_info").val();
                let rating = $("#modal-area-zone-seat input:radio[name='rating']:checked").val();

                $("#modal-area-zone-seat .alert").remove();
                if (!Number.isInteger(rating * 1) || rating < 1 || rating > 3) {
                    let ratingErrorMassage = $(this).attr('data-rating-error-message');
                    prependAlert('#edit-area-zone-seat-button-wrapper', ratingErrorMassage, 'danger', null, '#modal-area-zone-seat');
                    return false;
                }

                let rowAndSeatCombinationExist = false;
                let circles = $('#scene-wrapper circle.generated-seat');
                circles.each(function () {
                    let circleSeatId = $(this).attr('id');
                    let circleRowInfo = $(this).attr('row_info');
                    let circleSeatInfo = $(this).attr('seat_info');
                    if (isNaN(rowInfo)) {
                        rowInfo = rowInfo.toUpperCase();
                    }
                    if (isNaN(seatInfo)) {
                        seatInfo = seatInfo.toUpperCase();
                    }
                    if (circleRowInfo == rowInfo && circleSeatInfo == seatInfo && circleSeatId != seatId) {
                        rowAndSeatCombinationExist = true;
                    }
                });
                if (rowAndSeatCombinationExist) {
                    let rowAndSeatInfoErrorMassage = $(this).attr('data-row-and-seat-info-exist--error-message');
                    prependAlert('#edit-area-zone-seat-button-wrapper', rowAndSeatInfoErrorMassage, 'danger', null, '#modal-area-zone-seat');
                    return false;
                }

                let color = generateColor(rating * 1);
                $("#scene-wrapper circle#" + seatId).attr("row_info", rowInfo);
                $("#scene-wrapper circle#" + seatId).attr("seat_info", seatInfo);
                $("#scene-wrapper circle#" + seatId).attr("rating", rating);
                $("#scene-wrapper circle#" + seatId).attr("style", "fill:" + color);

                $('#modal-area-zone-seat').modal('hide');
                return false;

            });

            let seatRatingTool = null;
            $(document).on("click", ".seat-rating-tool", function () {
                if ($('#seat-rating-tool-switch').prop("checked") == true) {
                    let rating = $(this).attr('rating');
                    $('.seat-rating-description').css('font-weight', 'normal');
                    $('#seat-rating-' + rating).css('font-weight', 'bold');
                    seatRatingTool = rating * 1;
                }
            });

            $('#seat-rating-tool-switch').on('click', function () {
                if ($(this).prop("checked") == true) {
                    $('#seat-information svg circle').each(function () {
                        let rating = $(this).attr('rating');
                        let color = generateColor(rating * 1);
                        $(this).css('fill', color);
                    });
                    seatRatingToolRatingDescription(true);
                } else {
                    seatRatingTool = null;
                    $('#seat-information svg circle').css('fill', '#ebebeb');
                    seatRatingToolRatingDescription(false);
                }
            });

            let seatRatingToolRatingDescriptionPosition = false;

            function seatRatingToolRatingDescription(showRatingToolRatingDescription) {
                if (showRatingToolRatingDescription) {
                    if ($('#seat-rating-description-wrapper').hasClass('d-none')) {
                        $('#seat-rating-description-wrapper').removeClass('d-none');
                    }
                    if (!seatRatingToolRatingDescriptionPosition) {
                        let seatRatingToolRating0 = $('#seat-rating-tool-0').offset().left;
                        let seatRatingToolRating1 = $('#seat-rating-tool-1').offset().left;
                        let seatRatingToolRating2 = $('#seat-rating-tool-2').offset().left;
                        let seatRatingToolRating3 = $('#seat-rating-tool-3').offset().left;
                        const seatRating0 = $('#seat-rating-0').offset().left;
                        const seatRating1 = $('#seat-rating-1').offset().left;
                        const seatRating2 = $('#seat-rating-2').offset().left;
                        const seatRating3 = $('#seat-rating-3').offset().left;
                        $('#seat-rating-0').css('left', seatRatingToolRating0 - seatRating0 + 1 + 'px');
                        $('#seat-rating-1').css('left', seatRatingToolRating1 - seatRating1 + 7 + 'px');
                        $('#seat-rating-2').css('left', seatRatingToolRating2 - seatRating2 + 7 + 'px');
                        $('#seat-rating-3').css('left', seatRatingToolRating3 - seatRating3 + 7 + 'px');
                        $('#seat-rating-description').css('left', seatRatingToolRating3 - seatRating3 + 20 + 'px');
                        seatRatingToolRatingDescriptionPosition = true;
                    }
                } else {
                    $('.seat-rating-description').css('font-weight', 'normal');
                    if (!$('#seat-rating-description-wrapper').hasClass('d-none')) {
                        $('#seat-rating-description-wrapper').addClass('d-none');
                    }
                }
            }

        }

    });
