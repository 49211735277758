$(document).ready(
    function () {

        let organizer_new_ajax_lock = false;

        $(document).on("submit", "#organizer_register_from_seller_form", function () {

            if ($(".alert").length) {
                $(".alert").remove();
            }

            if (!organizer_new_ajax_lock) {

                organizer_new_ajax_lock = true;

                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer/new_from_seller",
                    data: {
                        serialized_post: $(this).serialize()
                    }
                })
                    .done(function (data) {

                        if (data.registerFromSeller !== undefined && data.registerFromSeller !== null) {
                            if (!$("#organizer_register_from_seller_form").hasClass('d-none')) {
                                $("#organizer_register_from_seller_form").addClass('d-none')
                            }
                            if ($("#organizer_register_success_message").hasClass('d-none')) {
                                $("#organizer_register_success_message").removeClass('d-none')
                            }
                        }

                    })
                    .fail(function (data) {

                        if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                            prependAlert('#div_organizer_register_button', data.responseJSON.error_message, 'danger', null, '#organizer_register_from_seller_form')
                        }

                        organizer_new_ajax_lock = false;

                    })
                    .always(function () {
                        organizer_new_ajax_lock = false;
                    });

            }

            return false;

        });

    }
)
;