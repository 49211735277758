if (typeof tinymce != 'undefined') {
    tinymce.addI18n('nb_NO', {
        "Redo": "Gj\u00f8r om",
        "Undo": "Angre",
        "Cut": "Klipp ut",
        "Copy": "Kopier",
        "Paste": "Lim inn",
        "Select all": "Marker alt",
        "New document": "Nytt dokument",
        "Ok": "Ok",
        "Cancel": "Avbryt",
        "Visual aids": "Visuelle hjelpemidler",
        "Bold": "Fet",
        "Italic": "Kursiv",
        "Underline": "Understreking",
        "Strikethrough": "Gjennomstreking",
        "Superscript": "Hevet skrift",
        "Subscript": "Senket skrift",
        "Clear formatting": "Fjern formateringer",
        "Align left": "Venstrejuster",
        "Align center": "Midtstill",
        "Align right": "H\u00f8yrejuster",
        "Justify": "Blokkjuster",
        "Bullet list": "Punktliste",
        "Numbered list": "Nummerliste",
        "Decrease indent": "Reduser innrykk",
        "Increase indent": "\u00d8k innrykk",
        "Close": "Lukk",
        "Formats": "Stiler",
        "Your browser doesn't support direct access to the clipboard. Please use the Ctrl+X\/C\/V keyboard shortcuts instead.": "Nettleseren din st\u00f8tter ikke direkte tilgang til utklippsboken. Bruk istedet tastatursnarveiene Ctrl+X\/C\/V.",
        "Headers": "Overskrifter",
        "Header 1": "Overskrift 1",
        "Header 2": "Overskrift 2",
        "Header 3": "Overskrift 3",
        "Header 4": "Overskrift 4",
        "Header 5": "Overskrift 5",
        "Header 6": "Overskrift 6",
        "Headings": "Overskrifter",
        "Heading 1": "Overskrift 1",
        "Heading 2": "Overskrift 2",
        "Heading 3": "Overskrift 3",
        "Heading 4": "Overskrift 4",
        "Heading 5": "Overskrift 5",
        "Heading 6": "Overskrift 6",
        "Preformatted": "Forh\u00e5ndsformatert",
        "Div": "Div",
        "Pre": "Pre",
        "Code": "Kode",
        "Paragraph": "Avsnitt",
        "Blockquote": "Blockquote",
        "Inline": "Innkapslet ",
        "Blocks": "Blokker",
        "Paste is now in plain text mode. Contents will now be pasted as plain text until you toggle this option off.": "Lim inn er n\u00e5 i ren-tekst modus. Kopiert innhold vil bli limt inn som ren tekst inntil du sl\u00e5r av dette valget.",
        "Fonts": "Fonter",
        "Font Sizes": "Fontst\u00f8rrelser",
        "Class": "Klasse",
        "Browse for an image": "S\u00f8k etter bilde",
        "OR": "ELLER",
        "Drop an image here": "Slipp et bilde her",
        "Upload": "Last opp",
        "Block": "Blokk",
        "Align": "Juster",
        "Default": "Normal",
        "Circle": "\u00c5pen sirkel",
        "Disc": "Fylt sirkel",
        "Square": "Fylt firkant",
        "Lower Alpha": "Minuskler",
        "Lower Greek": "Greske minuskler",
        "Lower Roman": "Romerske minuskler",
        "Upper Alpha": "Versaler",
        "Upper Roman": "Romerske versaler",
        "Anchor...": "Lenke",
        "Name": "Navn",
        "Id": "Id",
        "Id should start with a letter, followed only by letters, numbers, dashes, dots, colons or underscores.": "Id burde starte med en bokstav, bare fulgt av bokstaver, nummer, streker, punktum, koloner eller understreker.",
        "You have unsaved changes are you sure you want to navigate away?": "Du har ikke arkivert endringene. Vil du fortsette uten \u00e5 arkivere?",
        "Restore last draft": "Gjenopprett siste utkast",
        "Special characters...": "Spesialtegn",
        "Source code": "Kildekode",
        "Insert\/Edit code sample": "Sett inn\/endre kodeeksempel",
        "Language": "Spr\u00e5k",
        "Code sample...": "Kodeeksempel",
        "Color Picker": "Fargevelger",
        "R": "R",
        "G": "G",
        "B": "B",
        "Left to right": "Venstre til h\u00f8yre",
        "Right to left": "H\u00f8yre til venstre",
        "Emoticons...": "Emoticons..",
        "Metadata and Document Properties": "Metadata og dokumentverdier",
        "Title": "Tittel",
        "Keywords": "N\u00f8kkelord",
        "Description": "Beskrivelse",
        "Robots": "Roboter",
        "Author": "Forfatter",
        "Encoding": "Tegnkoding",
        "Fullscreen": "Fullskjerm",
        "Action": "Handling",
        "Shortcut": "Snarvei",
        "Help": "Hjelp",
        "Address": "Adresse",
        "Focus to menubar": "Fokus p\u00e5 menylinje",
        "Focus to toolbar": "Fokus p\u00e5 verkt\u00f8ylinje",
        "Focus to element path": "Fokus p\u00e5 elementsti",
        "Focus to contextual toolbar": "Fokus p\u00e5 kontekstuell verkt\u00f8ylinje",
        "Insert link (if link plugin activated)": "Sett inn lenke (dersom lenketillegg er aktivert)",
        "Save (if save plugin activated)": "Lagre (dersom lagretillegg er aktivert)",
        "Find (if searchreplace plugin activated)": "Finn (dersom tillegg for s\u00f8k og erstatt er aktivert)",
        "Plugins installed ({0}):": "Installerte tillegg ({0}):",
        "Premium plugins:": "Premiumtillegg:",
        "Learn more...": "Les mer ...",
        "You are using {0}": "Du bruker {0}",
        "Plugins": "Tillegg",
        "Handy Shortcuts": "Nyttige snarveier",
        "Horizontal line": "Horisontal linje",
        "Insert\/edit image": "Sett inn\/endre bilde",
        "Image description": "Bildebeskrivelse",
        "Source": "Bildelenke",
        "Dimensions": "Dimensjoner",
        "Constrain proportions": "Behold proporsjoner",
        "General": "Generelt",
        "Advanced": "Avansert",
        "Style": "Stil",
        "Vertical space": "Vertikal marg",
        "Horizontal space": "Horisontal marg",
        "Border": "Ramme",
        "Insert image": "Sett inn bilde",
        "Image...": "Bilde...",
        "Image list": "Bildeliste",
        "Rotate counterclockwise": "Roter mot venstre",
        "Rotate clockwise": "Roter mot h\u00f8yre",
        "Flip vertically": "Speilvend vertikalt",
        "Flip horizontally": "Speilvend horisontalt",
        "Edit image": "Rediger bilde",
        "Image options": "Bilde innstillinger",
        "Zoom in": "Zoom inn",
        "Zoom out": "Zoom ut",
        "Crop": "Beskj\u00e6r",
        "Resize": "Skaler",
        "Orientation": "Orientering",
        "Brightness": "Lysstyrke",
        "Sharpen": "Skarphet",
        "Contrast": "Kontrast",
        "Color levels": "Fargeniv\u00e5",
        "Gamma": "Gamma",
        "Invert": "Inverter",
        "Apply": "Utf\u00f8r",
        "Back": "Tilbake",
        "Insert date\/time": "Sett inn dato\/tid",
        "Date\/time": "Dato\/tid",
        "Insert\/Edit Link": "Sett inn \/ Rediger lenke",
        "Insert\/edit link": "Sett inn\/endre lenke",
        "Text to display": "Tekst som skal vises",
        "Url": "Url",
        "Open link in...": "\u00c5pne lenke i..",
        "Current window": "N\u00e5v\u00e6rende vindu",
        "None": "Ingen",
        "New window": "Nytt vindu",
        "Remove link": "Fjern lenke",
        "Anchors": "Anker",
        "Link...": "Lenke...",
        "Paste or type a link": "Lim inn eller skriv en lenke",
        "The URL you entered seems to be an email address. Do you want to add the required mailto: prefix?": "Oppgitte URL ser ut til \u00e5 v\u00e6re en epost-adresse. \u00d8nsker du \u00e5 sette inn p\u00e5krevet mailto: prefiks forran epost-adressen?",
        "The URL you entered seems to be an external link. Do you want to add the required http:\/\/ prefix?": "Oppgitt URL ser ut til \u00e5 v\u00e6re en e-postadresse. \u00d8nsker du \u00e5 sette inn p\u00e5krevd mailto:-prefiks foran e-postadressen?",
        "Link list": "Lenkeliste",
        "Insert video": "Sett inn video",
        "Insert\/edit video": "Sett inn\/rediger video",
        "Insert\/edit media": "Sett inn\/endre media",
        "Alternative source": "Alternativ kilde",
        "Alternative source URL": "Alternativ kilde URL",
        "Media poster (Image URL)": "Mediaposter (bilde-URL)",
        "Paste your embed code below:": "Lim inn  inkluderings-koden nedenfor",
        "Embed": "Inkluder",
        "Media...": "Media..",
        "Nonbreaking space": "Hardt mellomrom",
        "Page break": "Sideskifte",
        "Paste as text": "Lim inn som tekst",
        "Preview": "Forh\u00e5ndsvisning",
        "Print...": "Skriv ut...",
        "Save": "Arkiver",
        "Find": "Finn",
        "Replace with": "Erstatt med",
        "Replace": "Erstatt",
        "Replace all": "Erstatt alle",
        "Previous": "Forrige",
        "Next": "Neste",
        "Find and replace...": "Finn og erstatt...",
        "Could not find the specified string.": "Kunne ikke finne den spesifiserte teksten",
        "Match case": "Match store og sm\u00e5 bokstaver",
        "Find whole words only": "Finn kun hele ord",
        "Spell check": "Stavekontroll",
        "Ignore": "Ignorer",
        "Ignore all": "Ignorer alle",
        "Finish": "Avslutt",
        "Add to Dictionary": "Legg til i ordliste",
        "Insert table": "Sett inn tabell",
        "Table properties": "Tabell egenskaper",
        "Delete table": "Slett tabell",
        "Cell": "Celle",
        "Row": "Rad",
        "Column": "Kolonne",
        "Cell properties": "Celle egenskaper",
        "Merge cells": "Sl\u00e5 sammen celler",
        "Split cell": "Splitt celle",
        "Insert row before": "Sett inn rad f\u00f8r",
        "Insert row after": "Sett in rad etter",
        "Delete row": "Slett rad",
        "Row properties": "Rad egenskaper",
        "Cut row": "Klipp ut rad",
        "Copy row": "Kopier rad",
        "Paste row before": "Lim inn rad f\u00f8r",
        "Paste row after": "Lim inn rad etter",
        "Insert column before": "Sett inn kolonne f\u00f8r",
        "Insert column after": "Sett inn kolonne etter",
        "Delete column": "Slett kolonne",
        "Cols": "Kolonner",
        "Rows": "Rader",
        "Width": "Bredde",
        "Height": "H\u00f8yde",
        "Cell spacing": "Celleavstand",
        "Cell padding": "Cellemarg",
        "Show caption": "Vis bildetekst",
        "Left": "Venstre",
        "Center": "Midtstilt",
        "Right": "H\u00f8yre",
        "Cell type": "Celletype",
        "Scope": "Omfang",
        "Alignment": "Justering",
        "H Align": "H Justering",
        "V Align": "V Justering",
        "Top": "Topp",
        "Middle": "Midten",
        "Bottom": "Bunn",
        "Header cell": "Topptekst-celle",
        "Row group": "Radgruppe",
        "Column group": "Kolonnegruppe",
        "Row type": "Rad-type",
        "Header": "Topptekst",
        "Body": "Br\u00f8dtekst",
        "Footer": "Bunntekst",
        "Border color": "Rammefarge",
        "Insert template...": "Sett inn mal..",
        "Templates": "Maler",
        "Template": "Mal",
        "Text color": "Tekstfarge",
        "Background color": "Bakgrunnsfarge",
        "Custom...": "Tilpass...",
        "Custom color": "Tilpasset farge",
        "No color": "Ingen farge",
        "Remove color": "Fjern farge",
        "Table of Contents": "Innholdsfortegnelse",
        "Show blocks": "Vis blokker",
        "Show invisible characters": "Vis skjulte tegn",
        "Word count": "Ordtelling",
        "Words: {0}": "Antall ord: {0}",
        "{0} words": "{0} ord",
        "File": "Arkiv",
        "Edit": "Rediger",
        "Insert": "Sett inn",
        "View": "Vis",
        "Format": "Format",
        "Table": "Tabell",
        "Tools": "Verkt\u00f8y",
        "Powered by {0}": "Redigert med {0}",
        "Rich Text Area. Press ALT-F9 for menu. Press ALT-F10 for toolbar. Press ALT-0 for help": "Tekstredigering. Tast ALT-F9 for meny. Tast ALT-F10 for verkt\u00f8ys-rader. Tast ALT-0 for hjelp.",
        "Image title": "Bildetittel",
        "Border width": "Bordbredde",
        "Border style": "Bordstil",
        "Error": "Feil",
        "Warn": "Advarsel",
        "Valid": "Gyldig",
        "To open the popup, press Shift+Enter": "For \u00e5 \u00e5pne popup, trykk Shift+Enter",
        "Rich Text Area. Press ALT-0 for help.": "Rik-tekstomr\u00e5de. Trykk ALT-0 for hjelp.",
        "System Font": "Systemfont",
        "Failed to upload image: {0}": "Opplasting av bilde feilet: {0}",
        "Failed to load plugin: {0} from url {1}": "Kunne ikke laste tillegg: {0} from url {1}",
        "Failed to load plugin url: {0}": "Kunne ikke laste tillegg url: {0}",
        "Failed to initialize plugin: {0}": "Kunne ikke initialisere tillegg: {0}",
        "example": "eksempel",
        "Search": "S\u00f8k",
        "All": "Alle",
        "Currency": "Valuta",
        "Text": "Tekst",
        "Quotations": "Sitater",
        "Mathematical": "Matematisk",
        "Extended Latin": "Utvidet latin",
        "Symbols": "Symboler",
        "Arrows": "Piler",
        "User Defined": "Brukerdefinert",
        "dollar sign": "dollartegn",
        "currency sign": "valutasymbol",
        "euro-currency sign": "Euro-valutasymbol",
        "colon sign": "kolon-symbol",
        "cruzeiro sign": "cruzeiro-symbol",
        "french franc sign": "franske franc-symbol",
        "lira sign": "lire-symbol",
        "mill sign": "mill-symbol",
        "naira sign": "naira-symbol",
        "peseta sign": "peseta-symbol",
        "rupee sign": "rupee-symbol",
        "won sign": "won-symbol",
        "new sheqel sign": "Ny sheqel-symbol",
        "dong sign": "dong-symbol",
        "kip sign": "kip-symbol",
        "tugrik sign": "tugrik-symbol",
        "drachma sign": "drachma-symbol",
        "german penny symbol": "tysk penny-symbol",
        "peso sign": "peso-symbol",
        "guarani sign": "quarani-symbol",
        "austral sign": "austral-symbol",
        "hryvnia sign": "hryvina-symbol",
        "cedi sign": "credi-symbol",
        "livre tournois sign": "livre tournois-symbol",
        "spesmilo sign": "spesmilo-symbol",
        "tenge sign": "tenge-symbol",
        "indian rupee sign": "indisk rupee-symbol",
        "turkish lira sign": "tyrkisk lire-symbol",
        "nordic mark sign": "nordisk mark-symbol",
        "manat sign": "manat-symbol",
        "ruble sign": "ruble-symbol",
        "yen character": "yen-symbol",
        "yuan character": "yuan-symbol",
        "yuan character, in hong kong and taiwan": "yuan-symbol, i Hongkong og Taiwan",
        "yen\/yuan character variant one": "yen\/yuan-symbol variant en",
        "Loading emoticons...": "Laster emoticons..",
        "Could not load emoticons": "Kunne ikke laste emoticons",
        "People": "Mennesker",
        "Animals and Nature": "Dyr og natur",
        "Food and Drink": "Mat og drikke",
        "Activity": "Aktivitet",
        "Travel and Places": "Reise og steder",
        "Objects": "Objekter",
        "Flags": "Flagg",
        "Characters": "Tegn",
        "Characters (no spaces)": "Tegn (uten mellomrom)",
        "Error: Form submit field collision.": "Feil: Skjemafelt innsendingskollisjon",
        "Error: No form element found.": "Feil: Intet skjemafelt funnet.",
        "Update": "Oppdater",
        "Color swatch": "Fargepalett",
        "Turquoise": "Turkis",
        "Green": "Gr\u00f8nn",
        "Blue": "Bl\u00e5",
        "Purple": "Lilla",
        "Navy Blue": "Marinebl\u00e5",
        "Dark Turquoise": "M\u00f8rk turkis",
        "Dark Green": "M\u00f8rkegr\u00f8nn",
        "Medium Blue": "Mellombl\u00e5",
        "Medium Purple": "Medium lilla",
        "Midnight Blue": "Midnattbl\u00e5",
        "Yellow": "Gul",
        "Orange": "Oransje",
        "Red": "R\u00f8d",
        "Light Gray": "Lys gr\u00e5",
        "Gray": "Gr\u00e5",
        "Dark Yellow": "M\u00f8rk gul",
        "Dark Orange": "M\u00f8rk oransje",
        "Dark Red": "M\u00f8rker\u00f8d",
        "Medium Gray": "Medium gr\u00e5",
        "Dark Gray": "M\u00f8rk gr\u00e5",
        "Black": "Svart",
        "White": "Hvit",
        "Switch to or from fullscreen mode": "Bytt til eller fra fullskjermmodus",
        "Open help dialog": "\u00c5pne hjelp-dialog",
        "history": "historikk",
        "styles": "stiler",
        "formatting": "formatering",
        "alignment": "justering",
        "indentation": "innrykk",
        "permanent pen": "permanent penn",
        "comments": "kommentarer",
        "Anchor": "Anker",
        "Special character": "Spesialtegn",
        "Code sample": "Kodeeksempel",
        "Color": "Farge",
        "Emoticons": "Hum\u00f8rfjes",
        "Document properties": "Dokumentegenskaper",
        "Image": "Bilde",
        "Insert link": "Sett inn lenke",
        "Target": "M\u00e5l",
        "Link": "Lenke",
        "Poster": "Plakatbilde",
        "Media": "Media",
        "Print": "Skriv ut",
        "Prev": "Forrige",
        "Find and replace": "Finn og erstatt",
        "Whole words": "Hele ord",
        "Spellcheck": "Stavekontroll",
        "Caption": "Tittel",
        "Insert template": "Sett inn mal"
    });
}