$(document).ready(
    function () {

        let p = $("body").attr("p");
        if (p === 'organizer_payout_reports') {

            let order_by = 'payout_report_organizer_id';
            let order_by_direction = 'desc';
            let payoutReportWithZeroAmountId = $('#payout-report-with-zero-amount');
            let payout_sum_is_also_zero;

            $(document).on("click", ".sort", function (e) {
                e.preventDefault();
                let page = 1;
                order_by = $(this).attr('id');
                order_by_direction = $(this).attr('data-order-direction');
                setParamsAndGetPayoutReportList(page)
            });

            $(document).on("click", ".pagination a", function (e) {
                e.preventDefault();
                let page = parseInt($(this).attr("href").substring(6));
                setParamsAndGetPayoutReportList(page)
            });

            function setParamsAndGetPayoutReportList(page) {
                payout_sum_is_also_zero = payoutReportWithZeroAmountId.val();
                if (payoutReportWithZeroAmountId.prop("checked") === false) {
                    payout_sum_is_also_zero = null;
                }
                getPayoutReportList(page, order_by, order_by_direction, payout_sum_is_also_zero);
            }

            function getPayoutReportList(page = 1, order_by = null, order_by_direction = 'asc', payout_sum_is_also_zero = null) {

                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_payout_reports/search",
                    data: {
                        page,
                        order_by,
                        order_by_direction,
                        payout_sum_is_also_zero
                    }
                })
                    .done(function (data) {

                        if (data.html !== undefined && data.html !== null) {
                            $("#payout-report-list").remove();
                            $(".pagination").remove();
                            $("#payout-report-with-zero-amount-div").after(data.html);
                        }
                        $('html,body').scrollTop(0);

                    })
                    .fail(function (data) {

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                    })
            }

        }

    }
);