$(document).ready(
    function () {

        let organizer_new_user_ajax_lock = false;

        $(document).on("submit", "#settings_register_new_user_form", function () {

            if (!organizer_new_user_ajax_lock) {

                organizer_new_user_ajax_lock = true;

                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_settings_users/new_user",
                    data: {
                        serialized_post: $(this).serialize()
                    }
                })
                    .done(function (data) {

                        if (data.newUser !== undefined && data.newUser != null) {
                            document.location.href = "/organizer/settings/users?success=1&user=" + data.newUser;
                        }
                    })
                    .fail(function (data) {

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                        if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                            appendAlert('#div_settings_register_new_user', data.responseJSON.error_message, 'danger', null, '#settings_register_new_user_form');
                        }

                        organizer_new_user_ajax_lock = false;

                    })
                    .always(function () {
                        organizer_new_user_ajax_lock = false;
                    });

            }

            return false;

        });

    }
)
;