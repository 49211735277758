$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p === 'event' && a === 'show') {
            let queueStart = $("#order_register_form").data('queue-start');
            if (queueStart !== undefined && queueStart !== null && queueStart !== '') {
                queueStart = parseInt(queueStart);
                setTimeout(function () {
                    location.reload();
                }, queueStart * 1000);
            }
        }
    }
);