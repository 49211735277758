$(document).ready(
    function () {

        let p = $("body").attr("p");
        if (p === 'event') {

            let eventAccessOrderButton = $('#countdown-next-button');
            let eventId = eventAccessOrderButton.data('event-id');

            let event_order_access_ajax_lock = false;
            $(document).on("click", '#countdown-next-button', function (e) {
                e.preventDefault();

                if (!event_order_access_ajax_lock) {
                    event_order_access_ajax_lock = true;
                    $.ajax({
                        type: 'POST',
                        url: "/ajax/event/event_order_access",
                        data: {event_id: eventId}
                    })
                        .done(function (data) {

                            if (data.redirect_url !== false) {
                                document.location.href = data.redirect_url;
                            }

                        })
                        .fail(function (data) {

                            event_order_access_ajax_lock = false;

                        })
                        .always(function () {
                            event_order_access_ajax_lock = false;
                        });

                }

            });

        }

    }
);

