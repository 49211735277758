$(document).ready(
    function () {

        let p = $("body").attr("p");
        if (p === 'organizer_event_subevent_sales_event_ticket_category') {

            let eventId = $("body").attr("event_id");
            let eventSubeventId = $("body").attr("event_subevent_id");
            let startDate = $('#start-date').val();
            let endDate = $('#end-date').val();
            let period = 'week';
            let orderOrigin = $("#order_origin").children("option:selected").val();
            let startDateId = $('#start-date');
            let endDateId = $('#end-date');
            let periodHourId = $('#period-hour');
            let periodWeekId = $('#period-week');
            let eventStatistic = $('.event-statistic');
            let eventSalesHref = $('#event-sales').attr('href');
            if (periodWeekId.prop("checked") === false) {
                periodWeekId.prop("checked", true);
            }

            if ($("body").attr("start_date") !== undefined) {
                startDate = $("body").attr("start_date");
                startDateId.val(startDate);
            }
            if ($("body").attr("end_date") !== undefined) {
                endDate = $("body").attr("end_date");
                endDateId.val(endDate);
            }
            if ($("body").attr("period") !== undefined) {
                period = $("body").attr("period");
                $("input[name=period][value=" + period + "]").prop('checked', true);
            }
            if ($("body").attr("order_origin") !== undefined) {
                orderOrigin = $("body").attr("order_origin");
                $("#order_origin").val(orderOrigin);
            }

            let params = {
                event_id: eventId,
                event_subevent_id: eventSubeventId,
                start: startDate,
                end: endDate,
                period: period,
                order_origin: orderOrigin
            };

            addEventSalesDateAndPeriodToLink(eventSalesHref, startDate, endDate, period, orderOrigin);
            ajaxSubmitParamsAndGetEventSalesInformation(params);

            startDateId.change(function () {
                disablePeriodHour();
            });

            endDateId.change(function () {
                disablePeriodHour();
            });

            eventStatistic.change(function () {
                startDate = $('#start-date').val();
                endDate = $('#end-date').val();
                period = $('input[name=period]:checked').val();
                orderOrigin = $("#order_origin").children("option:selected").val();
                addEventSalesDateAndPeriodToLink(eventSalesHref, startDate, endDate, period, orderOrigin);
                $("#event_statistic_form").submit();
            });

            $('[data-toggle="tooltip"]').tooltip();

            $(document).on("submit", "#event_statistic_form", function (e) {
                e.preventDefault();
                ajaxSubmitParamsAndGetEventSalesInformation($(this).serialize());
            });


            function ajaxSubmitParamsAndGetEventSalesInformation(params) {
                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_event_subevent_sales_event_ticket_category/sales",
                    data: params
                })
                    .done(function (data) {
                        barChart(data);
                        salesSummaryTable(data);
                    })
                    .fail(function (data) {

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                    })

            }

            function barChart(primalData) {

                Highcharts.chart('event-bar-graph', {
                    chart: {type: 'column'},
                    exporting: {
                        buttons: {
                            contextButton: {
                                menuItems: [
                                    'viewFullscreen',
                                    'printChart',
                                    'separator',
                                    'downloadPNG',
                                    'downloadJPEG',
                                    'downloadPDF',
                                    'downloadSVG',
                                    'separator',
                                    'downloadCSV',
                                    'downloadXLS'
                                ]
                            }
                        }
                    },
                    title: false,
                    xAxis: {
                        categories: primalData.date,
                        title: {text: $("#event-bar-graph").attr("data-x-axis-text")}
                    },
                    yAxis: {
                        title: {text: $("#event-bar-graph").attr("data-y-axis-text")}
                    },
                    credits: {enabled: false},
                    series: primalData.ticket
                });

            }


            function salesSummaryTable(primalData) {

                let tableSalesSummary = $("#event-ticket-category-statistic");
                tableSalesSummary.empty();

                let eventTicketCategoryName;
                let eventTicketCategoryTicketSold;
                let eventTicketCategoryRevenue;
                let numberOfDiscountedTickets;
                let discountedAmount;
                let numberOfDiscountedTicketsTotal = 0;
                let discountedAmountTotal = 0;
                let eventTicketCategoryTicketSoldTotal = 0;
                let eventTicketCategoryRevenueTotal = 0;

                $.each(primalData.ticket, function () {

                    eventTicketCategoryName = '';
                    eventTicketCategoryTicketSold = 0;
                    eventTicketCategoryRevenue = 1;
                    numberOfDiscountedTickets = 0;
                    discountedAmount = 0;

                    $.each(this, function (name, value) {
                        if (name === 'name') {
                            eventTicketCategoryName = value;
                        }
                        if (name === 'data') {
                            eventTicketCategoryTicketSold = parseInt(value.reduce((a, b) => a + b, 0));
                            eventTicketCategoryTicketSoldTotal += eventTicketCategoryTicketSold;
                        }
                        if (name === 'revenue') {
                            eventTicketCategoryRevenue = parseFloat(value.reduce((c, d) => parseFloat(c) + parseFloat(d), 0));
                            eventTicketCategoryRevenueTotal += eventTicketCategoryRevenue;
                        }
                        if (name === 'discounted_tickets') {
                            numberOfDiscountedTickets = value;
                            numberOfDiscountedTicketsTotal += numberOfDiscountedTickets;
                        }
                        if (name === 'discounted_amount') {
                            discountedAmount = value;
                            discountedAmountTotal += discountedAmount;
                        }
                    });
                    salesSummaryTableTr(tableSalesSummary, eventTicketCategoryName, eventTicketCategoryTicketSold, eventTicketCategoryRevenue, numberOfDiscountedTickets, discountedAmount);
                });
                salesSummaryTableTotalTr(tableSalesSummary, eventTicketCategoryTicketSoldTotal, eventTicketCategoryRevenueTotal, numberOfDiscountedTicketsTotal, discountedAmountTotal);
            }


            function salesSummaryTableTr(tableSalesSummary, eventTicketCategoryName, eventTicketCategoryTicketSold, eventTicketCategoryRevenue, numberOfDiscountedTickets, discountedAmount) {
                if (numberOfDiscountedTickets > 0) {
                    tableSalesSummary.append($('<tr data-toggle="tooltip" title="Antall rabatterte billetter: ' + numberOfDiscountedTickets + ' stk' + "\n" + 'Sum rabatt eks mva: ' + discountedAmount.format(2, 3, ' ', ',') + ' kr">')
                        .append($('<td class="text-center">').append(eventTicketCategoryName))
                        .append($('<td class="text-center">').append(eventTicketCategoryTicketSold))
                        .append($('<td class="text-center">').append('kr ' + eventTicketCategoryRevenue.format(2, 3, ' ', ',')))
                    )
                } else {
                    tableSalesSummary.append($('<tr>')
                        .append($('<td class="text-center">').append(eventTicketCategoryName))
                        .append($('<td class="text-center">').append(eventTicketCategoryTicketSold))
                        .append($('<td class="text-center">').append('kr ' + eventTicketCategoryRevenue.format(2, 3, ' ', ',')))
                    )
                }
            }


            function salesSummaryTableTotalTr(tableSalesSummary, eventTicketCategoryTicketSoldTotal, eventTicketCategoryRevenueTotal, numberOfDiscountedTicketsTotal, discountedAmountTotal) {

                if (numberOfDiscountedTicketsTotal > 0) {
                    tableSalesSummary.append($('<tr data-toggle="tooltip" title="Antall rabatterte billetter: ' + numberOfDiscountedTicketsTotal + ' stk' + "\n" + 'Sum rabatt eks mva: ' + discountedAmountTotal.format(2, 3, ' ', ',') + ' kr">')
                        .append($('<td class="text-center">').append('<strong>Total</strong>'))
                        .append($('<td class="text-center">').append('<strong>' + eventTicketCategoryTicketSoldTotal + '</strong>'))
                        .append($('<td class="text-center">').append('<strong>kr ' + eventTicketCategoryRevenueTotal.format(2, 3, ' ', ',') + '</strong>'))
                    )
                } else {
                    tableSalesSummary.append($('<tr>')
                        .append($('<td class="text-center">').append('<strong>Total</strong>'))
                        .append($('<td class="text-center">').append('<strong>' + eventTicketCategoryTicketSoldTotal + '</strong>'))
                        .append($('<td class="text-center">').append('<strong>kr ' + eventTicketCategoryRevenueTotal.format(2, 3, ' ', ',') + '</strong>'))
                    )
                }
            }


            function disablePeriodHour() {
                if (startDateId.val().length !== 0 &&
                    endDateId.val().length !== 0 &&
                    (Date.parse(endDateId.val()) - Date.parse(startDateId.val())) < (172800 * 1000)) {
                    $(periodHourId).prop("disabled", false);
                } else {
                    if (periodHourId.prop("checked") === true) {
                        periodWeekId.prop("checked", true);
                    }
                    $(periodHourId).prop("disabled", true);
                }
            }

            function addEventSalesDateAndPeriodToLink(eventSalesHref, startDate, endDate, period, orderOrigin) {
                $('#event-sales').attr("href", eventSalesHref + "/" + startDate + "/" + endDate + "/" + period + "/" + orderOrigin);
            }

        }

    }
);
