$(document).ready(
    function () {

        if ($("#event-ticket-category").length) {
            let eventTicketCategory = document.getElementById("event-ticket-category");

            Sortable.create(eventTicketCategory, {
                handle: ".event-ticket-category-reorder",
                animation: 150,
                sort: true,
                delay: 0,
                touchStartThreshold: 0,
                disabled: false,
                draggable: ".event-ticket-category",
                direction: "horizontal",
                ghostClass: "sortable-ghost",
                dataIdAttr: "data-event-ticket-category-id",
                removeCloneOnHide: true,
                store: {
                    set: function (sortable) {
                        let order = sortable.toArray();
                        ajaxListOrder(order)
                    }
                }
            });

        }

        function ajaxListOrder($params) {

            console.log($params);

            $.ajax({
                type: 'POST',
                url: "/ajax/organizer_event_ticket_category/list_sort",
                data: {
                    serialized_post: JSON.stringify($params)
                }
            })
                .done(function (data) {

                    if (data === true) {
                        popupAlert();
                    }

                })
                .fail(function (data) {

                    if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                        document.location.href = data.responseJSON.redirect_url;
                    }

                });

        }

    }
);
