$(document).ready(
    function () {

        let p = $("body").attr("p");

        if (p == 'events' || p == 'frontpage') {

            let urlParams = new URLSearchParams(window.location.search);
            const page = urlParams.get('page');
            let organizer_id = 0;

            if (typeof $("body").attr("organizer_id") !== typeof undefined && $("body").attr("organizer_id") !== false) {
                organizer_id = parseInt($("body").attr("organizer_id"));
            }

            getEventList(page, organizer_id);


            $(document).on("click", ".pagination a", function (e) {

                e.preventDefault();

                let page = parseInt($(this).attr("href").substring(6));
                let search_string = $("#search_string").val();
                let organizer_id = 0;

                if ($("#organizer_id").length) {
                    organizer_id = parseInt($("#organizer_id").val());
                }

                getEventList(page, organizer_id, search_string);

            });

        }


        function getEventList(page = 1, organizer_id = 0, search_string = '') {
            $.ajax({
                type: 'POST',
                url: "/ajax/events/search",
                data: {
                    page,
                    search_string,
                    organizer_id
                }
            })
                .done(function (data) {

                    if (data.html !== undefined && data.html !== null) {
                        $("#event-public-list").remove();
                        $(".pagination").remove();
                        if ($("#event-public-list-organizer").length) {
                            $("#event-public-list-organizer").after(data.html);
                        } else {
                            $("#event-public-list-search").after(data.html);
                            if ($('#carousel-bottom').length) {
                                $('#carousel-bottom').carousel('cycle');
                            }
                        }
                    }

                    $('html,body').scrollTop(0);

                })
                .fail(function (data) {

                    if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                        document.location.href = data.responseJSON.redirect_url;
                    }

                })
        }


    }
);

