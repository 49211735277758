$(document).ready(
    function () {
        let p = $("body").attr("p");
        if (p === 'admin_fees') {
            $(document).on("click", "#show-deactivated-organizer-button", function () {
                $('#show-deactivated-organizer-button').remove();
                if ($('#deactivated-admin-organizer').hasClass('d-none')) {
                    $('#deactivated-admin-organizer').removeClass('d-none');
                }
            });
        }
    });