/**
 *
 * @param string message
 * @param string type
 * @param string title
 * @returns {string}
 */
function getAlertHtml(message, type, title = null) {

    var titleHtml = '';

    if (title != null && title != '') {
        titleHtml = '<strong>' + title + '</strong><br><br>';
    }

    return '<div class="alert alert-' + type + ' alert-dismissible" id="message"><a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>' + titleHtml + message + '</div>';

}

/**
 *
 * @param string message
 * @param string type
 * @param string title
 * @returns {string}
 */
function getAlertTable(message, type, title = null) {

    var titleHtml = '';

    if (title != null && title != '') {
        titleHtml = '<strong>' + title + '</strong><br><br>';
    }

    return '<tr><td colspan="3"><div class="alert alert-' + type + ' alert-dismissible" id="message"><a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>' + titleHtml + message + '</div></td></tr>';

}

/**
 *
 * @param string prependBeforeId
 * @param string message
 * @param string type
 * @param string title
 * @param string parent
 */
function prependAlert(prependBeforeId, message, type, title = null, parent = null) {

    if (parent != null && parent != '' && $(parent).length > 0) {
        $(".alert", $(parent)).remove();
    }

    $(getAlertHtml(message, type, title)).prependTo($(prependBeforeId));

}

/**
 *
 * @param string appendAfterId
 * @param string message
 * @param string type
 * @param string title
 * @param string parent
 */
function appendAlert(appendAfterId, message, type, title = null, parent = null) {

    if (parent != null && parent != '' && $(parent).length > 0) {
        $(".alert", $(parent)).remove();
    }

    $(getAlertHtml(message, type, title)).appendTo($(appendAfterId));

}

/**
 *
 * @param string insertAfterId
 * @param string message
 * @param string type
 * @param string title
 * @param string parent
 */
function inserAfterAlertTr(insertAfterId, message, type, title = null, parent = null) {

    if (parent != null && parent != '' && $(parent).length > 0) {
        $(".alert", $(parent)).remove();
    }

    $(getAlertTable(message, type, title)).insertAfter($(insertAfterId));

}

/**
 *
 */
function popupAlert() {

    if ($("#globalPopupMessage").length > 0) {
        $("#globalPopupMessage").remove();
    }

    var popup = '<div id="globalPopupMessage" class="bg-success text-white"><i class="material-icons">check_circle_outline</i></div>';

    $(popup).appendTo("#content");

    setTimeout(function () {
        $("#globalPopupMessage").fadeOut(500, function () {
            $("#globalPopupMessage").remove()
        });
    }, 500);

}

jQuery(document).ready(function () {

    $(document).on("click", ".markable-tr", function () {
        let hasClassBgLight = false;
        if ($(this).hasClass('bg-light')) {
            hasClassBgLight = true;
        }
        $('.markable-tr').each(function () {
            if ($(this).hasClass('bg-light')) {
                $(this).removeClass('bg-light');
            }
        });
        if (!hasClassBgLight) {
            $(this).addClass('bg-light');
        }
    });

    $(document).on("click", ".clickable-td", function () {
        window.location = $(this).closest("tr").data("href");
    });

    $(document).on("click", ".clickable-li", function () {
        window.location = $(this).closest("ul").data("href");
    });

    if ($('form.organizer-form').length) {
        $(document).on("change", "form.organizer-form input, form.organizer-form select, form.organizer-form textarea", function () {
            if (!$('form.organizer-form').hasClass('has-changed')) {
                $('form.organizer-form').addClass('has-changed');
            }
        });
        $(document).on("submit", "form.organizer-form", function () {
            if ($('form.organizer-form').hasClass('has-changed')) {
                $('form.organizer-form').removeClass('has-changed');
            }
        });
    }
    $(window).bind('beforeunload', function () {
        if ($('form.organizer-form').length && $('form.organizer-form').hasClass('has-changed')) {
            return 'Du har ikke lagret skjema - You have unsaved changes';
        }
    });

});


/**
 * Number.prototype.format(n, x, s, c)
 *
 * @param integer n: length of decimal
 * @param integer x: length of whole part
 * @param mixed   s: sections delimiter
 * @param mixed   c: decimal delimiter
 */
Number.prototype.format = function (n, x, s, c) {
    let re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
        num = this.toFixed(Math.max(0, ~~n));

    return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
};
