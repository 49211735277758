$(document).ready(
    function () {

        $(document).on("click", "#show-deactivated-area-button", function () {
            $('.deactivated-area').removeClass('d-none');
            $(this).remove();
        });

    });


