$(document).ready(
    function () {

        let p = $("body").attr("p");
        if (p === 'dealer_sales') {

            $(document).on("change", ".organizer-sales-data-trigger", function (e) {
                getSalesData();
            });

            $(document).on("click", ".pagination a", function (e) {
                e.preventDefault();
                let page = parseInt($(this).attr("href").substring(6));
                getSalesData(page)
            });

            getSalesData();

            $(document).on("change", "#sales_data_revenue_type", function (e) {
                let salesDataRevenueType = $("#sales_data_revenue_type option:selected").val();
                if (salesDataRevenueType == 'sales_data_revenue_type_license_and_fee') {
                    $("#start_date").val();
                    $("#end_date").val();
                    if ($(".select-sales-data-month-and-year-wrapper").hasClass('d-none')) {
                        $(".select-sales-data-month-and-year-wrapper").removeClass('d-none');
                    }
                    if (!$(".select-sales-data-datepicker-wrapper").hasClass('d-none')) {
                        $(".select-sales-data-datepicker-wrapper").addClass('d-none');
                    }
                    $('#month option[value=' + $("#month").attr('data-value') + ']').attr('selected', 'selected');
                    $('#year option[value=' + $("#year").attr('data-value') + ']').attr('selected', 'selected');
                }
                if (salesDataRevenueType == 'sales_data_revenue_type_license') {
                    $("#start_date").val();
                    $("#end_date").val();
                    if ($(".select-sales-data-month-and-year-wrapper").hasClass('d-none')) {
                        $(".select-sales-data-month-and-year-wrapper").removeClass('d-none');
                    }
                    if (!$(".select-sales-data-datepicker-wrapper").hasClass('d-none')) {
                        $(".select-sales-data-datepicker-wrapper").addClass('d-none');
                    }
                    $('#month option[value=' + $("#month").attr('data-value') + ']').attr('selected', 'selected');
                    $('#year option[value=' + $("#year").attr('data-value') + ']').attr('selected', 'selected');
                }
                if (salesDataRevenueType == 'sales_data_revenue_type_fee') {
                    $("#month").val();
                    $("#year").val();
                    if (!$(".select-sales-data-month-and-year-wrapper").hasClass('d-none')) {
                        $(".select-sales-data-month-and-year-wrapper").addClass('d-none');
                    }
                    if ($(".select-sales-data-datepicker-wrapper").hasClass('d-none')) {
                        $(".select-sales-data-datepicker-wrapper").removeClass('d-none');
                    }

                    addDatePicker();
                    $("#start_date").val($("#start_date").attr('data-value'));
                    $("#end_date").val($("#end_date").attr('data-value'));

                }
                getSalesData();
            });


            function addDatePicker() {
                $('.datepicker-sales-data-start-date').each(function () {
                    let $salesDataStartDate = $(this).closest('.form-row').find(".datepicker-sales-data-start-date").pickadate({
                        format: 'yyyy-mm-dd',
                        formatSubmit: 'yyyy-mm-dd',
                        editable: true
                    });
                    $salesDataStartDate.on('click', function (ev) {
                        let pickerStartDateSalesData = $salesDataStartDate.data('pickadate');
                        pickerStartDateSalesData.open();
                    });
                });
                $('.datepicker-sales-data-end-date').each(function () {
                    let $salesDataEndDate = $(this).closest('.form-row').find(".datepicker-sales-data-end-date").pickadate({
                        format: 'yyyy-mm-dd',
                        formatSubmit: 'yyyy-mm-dd',
                        editable: true
                    });
                    $salesDataEndDate.on('click', function (ev) {
                        let pickerEndDateSalesData = $salesDataEndDate.data('pickadate');
                        pickerEndDateSalesData.open();
                    });
                });
            }

            function getSalesData(page = 1) {
                let sellerId = $('#seller_id option:selected').val();
                let month = $('#month').val();
                let year = $('#year').val();
                let sellersSalesData = 0;
                if ($('#sellers-sales-data').is(":checked")) {
                    sellersSalesData = 1;
                }
                let salesDateRevenueType = $('#sales_data_revenue_type option:selected').val();
                let startDate = $('#start_date').val();
                let endDate = $('#end_date').val();

                $.ajax({
                    type: 'POST',
                    url: "/ajax/dealer_sales/search",
                    data: {
                        seller_id: sellerId,
                        month: month,
                        year: year,
                        start_date: startDate,
                        end_date: endDate,
                        sellers_sales_data: sellersSalesData,
                        page: page,
                        sales_data_revenue_type: salesDateRevenueType,
                    }
                })
                    .done(function (data) {

                        if ($('#sellers-sales-data').is(":checked")) {
                            $('#salesdata-type').text($('#salesdata-type').attr('data-type-seller'));
                        } else {
                            $('#salesdata-type').text($('#salesdata-type').attr('data-type-organizer'));
                        }

                        if (data.html !== undefined && data.html !== null) {
                            $('#organizer-sales-data').empty();
                            $('#organizer-sales-data').append(data.html);
                        }

                        if (data.sales_data_revenue_type == 'sales_data_revenue_type_license_and_fee') {
                            if ($(".sales-data-visit-tracker-key-figures").hasClass('d-none')) {
                                $(".sales-data-visit-tracker-key-figures").removeClass('d-none');
                            }
                            if ($(".sales-data-ticket-sales-key-figures").hasClass('d-none')) {
                                $(".sales-data-ticket-sales-key-figures").removeClass('d-none');
                            }
                            if ($(".sales-data-total-revenue-key-figures").hasClass('d-none')) {
                                $(".sales-data-total-revenue-key-figures").removeClass('d-none');
                            }
                            $(".sales-data-visit-tracker-key-figures").removeClass('col-md-4');
                            if (!$(".sales-data-visit-tracker-key-figures").hasClass('col-md-2')) {
                                $(".sales-data-visit-tracker-key-figures").addClass('col-md-2');
                            }
                            $(".sales-data-ticket-sales-key-figures").removeClass('col-md-4');
                            if (!$(".sales-data-ticket-sales-key-figures").hasClass('col-md-2')) {
                                $(".sales-data-ticket-sales-key-figures").addClass('col-md-2');
                            }
                            $(".sales-data-total-revenue-key-figures").removeClass('col-md-4');
                            if (!$(".sales-data-total-revenue-key-figures").hasClass('col-md-2')) {
                                $(".sales-data-total-revenue-key-figures").addClass('col-md-2');
                            }

                            if ($(".sales-data-visit-tracker-organizer").hasClass('d-none')) {
                                $(".sales-data-visit-tracker-organizer").removeClass('d-none');
                            }
                            if ($(".sales-data-ticket-sales-organizer").hasClass('d-none')) {
                                $(".sales-data-ticket-sales-organizer").removeClass('d-none');
                            }
                            if ($(".sales-data-total-revenue-organizer").hasClass('d-none')) {
                                $(".sales-data-total-revenue-organizer").removeClass('d-none');
                            }


                        }

                        if (data.sales_data_revenue_type == 'sales_data_revenue_type_license') {
                            if ($(".sales-data-visit-tracker-key-figures").hasClass('d-none')) {
                                $(".sales-data-visit-tracker-key-figures").removeClass('d-none');
                            }
                            if (!$(".sales-data-ticket-sales-key-figures").hasClass('d-none')) {
                                $(".sales-data-ticket-sales-key-figures").addClass('d-none');
                            }
                            if (!$(".sales-data-total-revenue-key-figures").hasClass('d-none')) {
                                $(".sales-data-total-revenue-key-figures").addClass('d-none');
                            }
                            $(".sales-data-visit-tracker-key-figures").removeClass('col-md-2');
                            if (!$(".sales-data-visit-tracker-key-figures").hasClass('col-md-4')) {
                                $(".sales-data-visit-tracker-key-figures").addClass('col-md-4');
                            }
                            $(".sales-data-ticket-sales-key-figures").removeClass('col-md-2');
                            if (!$(".sales-data-ticket-sales-key-figures").hasClass('col-md-4')) {
                                $(".sales-data-ticket-sales-key-figures").addClass('col-md-4');
                            }
                            $(".sales-data-total-revenue-key-figures").removeClass('col-md-2');
                            if (!$(".sales-data-total-revenue-key-figures").hasClass('col-md-4')) {
                                $(".sales-data-total-revenue-key-figures").addClass('col-md-4');
                            }

                            if ($(".sales-data-visit-tracker-organizer").hasClass('d-none')) {
                                $(".sales-data-visit-tracker-organizer").removeClass('d-none');
                            }
                            if (!$(".sales-data-ticket-sales-organizer").hasClass('d-none')) {
                                $(".sales-data-ticket-sales-organizer").addClass('d-none');
                            }
                            if (!$(".sales-data-total-revenue-organizer").hasClass('d-none')) {
                                $(".sales-data-total-revenue-organizer").addClass('d-none');
                            }

                        }

                        if (data.sales_data_revenue_type == 'sales_data_revenue_type_fee') {
                            if (!$(".sales-data-visit-tracker-key-figures").hasClass('d-none')) {
                                $(".sales-data-visit-tracker-key-figures").addClass('d-none');
                            }
                            if ($(".sales-data-ticket-sales-key-figures").hasClass('d-none')) {
                                $(".sales-data-ticket-sales-key-figures").removeClass('d-none');
                            }
                            if (!$(".sales-data-total-revenue-key-figures").hasClass('d-none')) {
                                $(".sales-data-total-revenue-key-figures").addClass('d-none');
                            }
                            $(".sales-data-visit-tracker-key-figures").removeClass('col-md-2');
                            if (!$(".sales-data-visit-tracker-key-figures").hasClass('col-md-4')) {
                                $(".sales-data-visit-tracker-key-figures").addClass('col-md-4');
                            }
                            $(".sales-data-ticket-sales-key-figures").removeClass('col-md-2');
                            if (!$(".sales-data-ticket-sales-key-figures").hasClass('col-md-4')) {
                                $(".sales-data-ticket-sales-key-figures").addClass('col-md-4');
                            }
                            $(".sales-data-total-revenue-key-figures").removeClass('col-md-2');
                            if (!$(".sales-data-total-revenue-key-figures").hasClass('col-md-4')) {
                                $(".sales-data-total-revenue-key-figures").addClass('col-md-4');
                            }

                            if (!$(".sales-data-visit-tracker-organizer").hasClass('d-none')) {
                                $(".sales-data-visit-tracker-organizer").addClass('d-none');
                            }
                            if ($(".sales-data-ticket-sales-organizer").hasClass('d-none')) {
                                $(".sales-data-ticket-sales-organizer").removeClass('d-none');
                            }
                            if (!$(".sales-data-total-revenue-organizer").hasClass('d-none')) {
                                $(".sales-data-total-revenue-organizer").addClass('d-none');
                            }
                        }

                        $(".pagination").remove();
                        if (data.pagination !== undefined && data.pagination !== null) {
                            $('#organizer-sales-list').after(data.pagination);
                        }

                        if (data.visit_tracker_commission !== undefined && data.visit_tracker_commission != null) {
                            $("#key-figures-visit-trackers").text(data.visit_tracker_commission + ' kr');
                        }
                        if (data.visit_tracker_commission_invoice !== undefined && data.visit_tracker_commission_invoice != null) {
                            $("#key-figures-visit-trackers-invoice").text(data.visit_tracker_commission_invoice + ' kr');
                        }
                        if (data.event_commission !== undefined && data.event_commission != null) {
                            $("#key-figures-events").text(data.event_commission + ' kr');
                        }
                        if (data.event_commission_invoice !== undefined && data.event_commission_invoice != null) {
                            $("#key-figures-events-invoice").text(data.event_commission_invoice + ' kr');
                        }
                        if (data.total_commission !== undefined && data.total_commission != null) {
                            $("#key-figures-total").text(data.total_commission + ' kr');
                        }
                        if (data.profit !== undefined && data.profit != null) {
                            $("#key-figures-profit").text(data.profit + ' kr');
                        }

                    })
                    .fail(function (data) {

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                    })
            }

        }

    }
);