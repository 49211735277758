$(document).ready(
    function () {

        let p = $("body").attr("p");

        if (p == 'events' || p == 'frontpage') {

            $(document).on("submit", "#event_public_search_form", function () {

                $.ajax({
                    type: 'POST',
                    url: "/ajax/events/search",
                    data: {
                        serialized_post: $(this).serialize()
                    }
                })
                    .done(function (data) {

                        if (data.html !== undefined && data.html !== null) {
                            $("#event-public-list").remove();
                            $(".pagination").remove();
                            if ($("#event-public-list-organizer").length) {
                                $("#event-public-list-organizer").after(data.html);
                            } else {
                                $("#event-public-list-search").after(data.html);
                            }
                        }

                    });

                return false;

            });

        }

    }
);