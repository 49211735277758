$(document).ready(
    function () {

        $(".organizer").on("click", function () {

            var selected_organizer = $(this).attr("data-organizer-id");

            $.ajax({
                type: 'POST',
                url: "/ajax/user/select_organizer",
                data: {
                    organizer_id: selected_organizer
                }
            })
                .done(function (data) {

                    if (data.redirect_url !== undefined && data.redirect_url != null) {
                        document.location.href = data.redirect_url;
                    }

                })
                .fail(function (data) {

                    if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url != null) {
                        document.location.href = data.responseJSON.redirect_url;
                    }

                });

            return false;

        });

    }
);