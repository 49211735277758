$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p === 'event' && a === 'show') {

            $(document).on("click", "#event-description #fadeout-button", function (e) {
                e.preventDefault();
                $('#event-description').addClass('view-all');
            });

        }
    }
);