$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p === 'profile' && a === 'show') {

            let profile_update_ajax_lock = false;

            $(document).on("submit", "#user-profile-form", function (e) {

                e.preventDefault();

                if (!profile_update_ajax_lock) {

                    profile_update_ajax_lock = true;

                    let formData = new FormData(document.getElementById('user-profile-form'));

                    $.ajax({
                        type: 'POST',
                        url: "/ajax/user/update",
                        data: formData,
                        async: true,
                        cache: false,
                        processData: false,
                        contentType: false
                    })
                        .done(function (data) {

                            if (data === true) {
                                popupAlert();
                            }

                        })
                        .fail(function (data) {

                            if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url != null) {
                                document.location.href = data.responseJSON.redirect_url;
                            }

                            if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                                prependAlert('#user-profile-update-button-wrapper', data.responseJSON.error_message, 'danger', null, '#user-profile-form')
                            }

                            profile_update_ajax_lock = false;

                        })
                        .always(function () {
                            profile_update_ajax_lock = false;
                        });

                }

            });

        }

    }
);