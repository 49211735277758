$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p === 'visit_tracker_location' && a === 'list') {

            $(document).on("click", "#show-deactivated-visit-tracker-location-button", function () {
                $('#show-deactivated-visit-tracker-location-button').remove();
                if ($('#deactivated-visit-tracker-location').hasClass('d-none')) {
                    $('#deactivated-visit-tracker-location').removeClass('d-none');
                }
            });

        }

    });