$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p === 'organizer_area' && a == 'new') {

            let area_registration_ajax_lock = false;

            $(document).on("submit", "#area_registration_form", function () {

                if (!area_registration_ajax_lock) {

                    area_registration_ajax_lock = true;

                    let formData = new FormData(document.getElementById('area_registration_form'));

                    $.ajax({
                        type: 'POST',
                        url: "/ajax/organizer_area/new",
                        data: {
                            serialized_post: $(this).serialize()
                        }
                    })
                        .done(function (data) {

                            if (data.message !== undefined && data.message !== null) {

                                prependAlert('#area_registration_button_wrapper', data.message, 'success', null, '#area_registration_form');
                                $('#area_registration_button').hide();

                                setTimeout(function () {
                                    document.location.href = "/organizer/area";
                                }, 3000);

                            }

                        })
                        .fail(function (data) {

                            if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                                document.location.href = data.responseJSON.redirect_url;
                            }

                            if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                                prependAlert('#div_event_register_button', data.responseJSON.error_message, 'danger', null, '#event_register_form')
                            }

                            area_registration_ajax_lock = false;

                        })
                        .always(function () {
                            area_registration_ajax_lock = false;
                        });

                }

                return false;

            });

        }

    }
);