$(document).ready(
    function () {

        let reservationAlert = $("#reservation-alert");
        let cancelReservationLink = $("#cancel-reservation-link");

        $(cancelReservationLink).click(function (e) {

            e.preventDefault();

            let eventTicketCategoryList = $("#event-ticket-category-list");
            let orderForm = $("#order-form");
            let orderSummary = $("#order-summary");
            let orderTerms = $("#order-terms");
            let orderNewsletter = $("#order-newsletter");
            let orderButton = $("#order-register-button");
            let cancelButton = $("#order-cancel-button");
            let nextButton = $("#order-next-button");

            orderButton.hide();
            cancelButton.hide();
            nextButton.show();
            orderForm.hide();
            orderSummary.hide();
            orderTerms.hide();


            eventTicketCategoryList.show();

            $.ajax({
                type: 'GET',
                url: "/ajax/order/cancel_reservation"
            })
                .done(function (data) {

                    if (data.message !== undefined && data.message !== null) {
                        removeDiscountCookie();
                        reservationAlert.text(data.message);
                        if (reservationAlert.hasClass('alert-info')) {
                            reservationAlert.removeClass('alert-info');
                            reservationAlert.addClass('alert-warning');
                        }
                        reservationAlert.removeAttr('data-event-id');
                        if ($('#reservation-alert-box').length !== 0) {
                            $('#reservation-alert-box').remove();
                        }
                    }

                })

        });

        function removeDiscountCookie() {
            let daysForCookieToExpire = 0;
            let cookieExpires = new Date(new Date().getTime() + daysForCookieToExpire * 1000 * 60 * 60 * 24);
            document.cookie = "discount = ;expires=" + cookieExpires + "; path=/";
        }

    }
);

