$(document).ready(
    function () {

        let p = $("body").attr("p");
        if (p == 'organizer_event') {

            $(document).on("click", "#all_day_ticket", function () {

                if ($(this).is(":checked")) {
                    $('#end_date').val($('#start_date').val());
                    $('#start_time').val('00:00');
                    $('#end_time').val('23:59');
                    $('.data_and_time_wrapper').each(function () {
                        if (!$(this).hasClass('d-none')) {
                            $(this).addClass('d-none');
                        }
                    });

                } else {
                    $('#end_date').val('');
                    $('#start_time').val('');
                    $('#end_time').val('');
                    $('.data_and_time_wrapper').each(function () {
                        if ($(this).hasClass('d-none')) {
                            $(this).removeClass('d-none');
                        }
                    });

                }

            });

            $(document).on("change", "#start_date", function () {
                if ($('#all_day_ticket').is(":checked")) {
                    $('#end_date').val($('#start_date').val());
                }
            });

            function isAllDayTicket() {
                if ($('#start_date').val() == $('#end_date').val() && $('#start_time').val() == '00:00' && $('#end_time').val() == '23:59') {
                    return true;
                }
                return false;
            }

            if (isAllDayTicket() === true) {
                $("#all_day_ticket").prop("checked", true);
                $('.data_and_time_wrapper').each(function () {
                    if (!$(this).hasClass('d-none')) {
                        $(this).addClass('d-none');
                    }
                });
            }

        }
    }
);