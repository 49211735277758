$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p == 'admin_slider' && a == 'new') {

            let slider_registration_ajax_lock = false;

            $(document).on("submit", "#slider-registration-form", function (e) {

                e.preventDefault();

                if (!slider_registration_ajax_lock) {

                    slider_registration_ajax_lock = true;

                    let formData = new FormData(document.getElementById('slider-registration-form'));

                    $.ajax({
                        type: 'POST',
                        url: "/ajax/admin_slider/new",
                        data: formData,
                        async: true,
                        cache: false,
                        processData: false,
                        contentType: false
                    })
                        .done(function (data) {

                            if (data.message !== undefined && data.message !== null) {

                                prependAlert('#div_slider_register_button', data.message, 'success', null, '#slider-registration-form');
                                //$('.card-box-to-hide').hide();
                                //$('#slider_register_button').hide();

                                setTimeout(function () {
                                    document.location.href = "/admin/slider";
                                }, 3000);

                            }

                        })
                        .fail(function (data) {

                            if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                                document.location.href = data.responseJSON.redirect_url;
                            }

                            if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                                prependAlert('#div_slider_register_button', data.responseJSON.error_message, 'danger', null, '#slider_register_form')
                            }

                            slider_registration_ajax_lock = false;

                        })
                        .always(function () {
                            slider_registration_ajax_lock = false;
                        });

                }

                return false;

            });

        }

    }
);