$(document).ready(
    function () {

        if ($("#event_ticket_category_id").val() !== undefined && $("#event_ticket_category_id").val() != null && $("#event_ticket_category_id").val() !== "") {

            let event_ticket_category_update_ajax_lock = false;

            $(document).on("submit", "#event_ticket_category_form", function () {

                if (!event_ticket_category_update_ajax_lock) {

                    let event_id = $("#event_id").val();

                    event_ticket_category_update_ajax_lock = true;

                    $(':disabled').each(function () {
                        $(this).removeAttr('disabled');
                    });

                    $.ajax({
                        type: 'POST',
                        url: "/ajax/organizer_event_ticket_category/update",
                        data: {
                            serialized_post: $(this).serialize()
                        }
                    })
                        .done(function (data) {

                            if (data.message !== undefined && data.message !== null) {

                                prependAlert('#div_event_ticket_category_button', data.message, 'success', null, '#event_ticket_category_form');
                                $('.card-box-to-hide').hide();
                                $('#event_ticket_category_button').hide();

                                setTimeout(function () {
                                    document.location.href = "/organizer/event/" + event_id + "/ticket_category/";
                                }, 2000);

                            }

                        })
                        .fail(function (data) {

                            if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                                document.location.href = data.responseJSON.redirect_url;
                            }

                            if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                                prependAlert('#div_event_ticket_category_button', data.responseJSON.error_message, 'danger', null, '#event_ticket_category_form')
                            }

                            event_ticket_category_update_ajax_lock = false;

                        })
                        .always(function () {
                            event_ticket_category_update_ajax_lock = false;
                        });

                }

                return false;

            });

        }

    }
);