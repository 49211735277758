$(document).ready(
    function () {

        let p = $("body").attr("p");

        if (p === 'organizer_event_subevent_sales') {

            let eventId = $("body").attr("event_id");
            let eventSubeventId = $("body").attr("event_subevent_id");
            let startDate = $('#start-date').val();
            let endDate = $('#end-date').val();
            let period = 'week';
            let orderOrigin = $("#order_origin").children("option:selected").val();
            let numberOfSoldTicketsId = $('#key-figures-number-of-sold-tickets');
            let eventRevenueId = $('#key-figures-event-revenue');
            let eventFeeId = $('#key-figures-event-fee');
            let startDateId = $('#start-date');
            let endDateId = $('#end-date');
            let periodHourId = $('#period-hour');
            let periodWeekId = $('#period-week');
            let eventStatistic = $('.event-statistic');
            let eventTicketCategorySalesHref = $('#event-ticket-category-sales').attr('href');
            if (periodWeekId.prop("checked") === false) {
                periodWeekId.prop("checked", true);
            }

            if ($("body").attr("start_date") !== undefined) {
                startDate = $("body").attr("start_date");
                startDateId.val(startDate);
            }
            if ($("body").attr("end_date") !== undefined) {
                endDate = $("body").attr("end_date");
                endDateId.val(endDate);
            }
            if ($("body").attr("period") !== undefined) {
                period = $("body").attr("period");
                $("input[name=period][value=" + period + "]").prop('checked', true);
            }
            if ($("body").attr("order_origin") !== undefined) {
                orderOrigin = $("body").attr("order_origin");
                $("#order_origin").val(orderOrigin);
            }

            let params = {
                event_id: eventId,
                event_subevent_id: eventSubeventId,
                start: startDate,
                end: endDate,
                period: period,
                order_origin: orderOrigin
            };

            addEventTicketCategorySalesDateAndPeriodToLink(eventTicketCategorySalesHref, startDate, endDate, period, orderOrigin);
            ajaxSubmitParamsAndGetEventSalesInformation(params);

            startDateId.change(function () {
                disablePeriodHour();
            });

            endDateId.change(function () {
                disablePeriodHour();
            });

            eventStatistic.change(function () {
                startDate = $('#start-date').val();
                endDate = $('#end-date').val();
                period = $('input[name=period]:checked').val();
                orderOrigin = $("#order_origin").children("option:selected").val();
                addEventTicketCategorySalesDateAndPeriodToLink(eventTicketCategorySalesHref, startDate, endDate, period, orderOrigin);
                $("#event_statistic_form").submit();
            });

            $('[data-toggle="tooltip"]').tooltip();

            $(document).on("submit", "#event_statistic_form", function (e) {
                e.preventDefault();
                ajaxSubmitParamsAndGetEventSalesInformation($(this).serialize());
            });


            function ajaxSubmitParamsAndGetEventSalesInformation(params) {
                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_event_subevent_sales/sales",
                    data: params
                })
                    .done(function (data) {
                        let numberOfSoldTickets = data.number_of_sold_tickets;
                        let eventRevenue = data.event_revenue;
                        let eventFee = data.event_fee;
                        numberOfSoldTicketsId.text(numberOfSoldTickets);
                        eventRevenueId.text("kr " + parseFloat(eventRevenue).format(2, 3, ' ', ','));
                        eventFeeId.text("(gebyr kr " + parseFloat(eventFee).format(2, 3, ' ', ',') + ")");
                        barChartRevenue(data);
                        barChartSoldTickets(data);
                    })
                    .fail(function (data) {

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                    })

            }

            function barChartRevenue(primalData) {

                Highcharts.chart('event-bar-graph-subevent-revenue', {
                    chart: {type: 'column'},
                    exporting: {
                        buttons: {
                            contextButton: {
                                menuItems: [
                                    'viewFullscreen',
                                    'printChart',
                                    'separator',
                                    'downloadPNG',
                                    'downloadJPEG',
                                    'downloadPDF',
                                    'downloadSVG',
                                    'separator',
                                    'downloadCSV',
                                    'downloadXLS'
                                ]
                            }
                        }
                    },
                    title: false,
                    xAxis: {
                        categories: primalData.date,
                        title: {text: $("#event-bar-graph-subevent-revenue").attr("data-x-axis-text")}
                    },
                    yAxis: {
                        title: {text: $("#event-bar-graph-subevent-revenue").attr("data-y-axis-text")}
                    },
                    credits: {enabled: false},
                    series: [{
                        showInLegend: false,
                        data: primalData.revenue,
                        name: $("#event-bar-graph-subevent-revenue").attr("data-y-axis-text")
                    }]
                });
            }

            function barChartSoldTickets(primalData) {

                Highcharts.chart('event-bar-graph', {
                    chart: {type: 'column'},
                    exporting: {
                        buttons: {
                            contextButton: {
                                menuItems: [
                                    'viewFullscreen',
                                    'printChart',
                                    'separator',
                                    'downloadPNG',
                                    'downloadJPEG',
                                    'downloadPDF',
                                    'downloadSVG',
                                    'separator',
                                    'downloadCSV',
                                    'downloadXLS'
                                ]
                            }
                        }
                    },
                    title: false,
                    xAxis: {
                        categories: primalData.date,
                        title: {text: $("#event-bar-graph").attr("data-x-axis-text")}
                    },
                    yAxis: {
                        title: {text: $("#event-bar-graph").attr("data-y-axis-text")}
                    },
                    credits: {enabled: false},
                    series: [{
                        showInLegend: false,
                        data: primalData.ticket,
                        name: $("#event-bar-graph").attr("data-y-axis-text")
                    }]
                });
            }


            function disablePeriodHour() {
                if (startDateId.val().length !== 0 &&
                    endDateId.val().length !== 0 &&
                    (Date.parse(endDateId.val()) - Date.parse(startDateId.val())) < (172800 * 1000)) {
                    $(periodHourId).prop("disabled", false);
                } else {
                    if (periodHourId.prop("checked") === true) {
                        periodWeekId.prop("checked", true);
                    }
                    $(periodHourId).prop("disabled", true);
                }
            }

            function addEventTicketCategorySalesDateAndPeriodToLink(eventTicketCategorySalesHref, startDate, endDate, period, orderOrigin) {
                $('#event-ticket-category-sales').attr("href", eventTicketCategorySalesHref + "/" + startDate + "/" + endDate + "/" + period + "/" + orderOrigin);
            }

        }

    });
