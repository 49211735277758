$(document).ready(
    function () {

        let p = $("body").attr("p");

        if (p === 'organizer_event_entered_customers' || p == 'organizer_event_subevent_entered_customers') {

            let eventId = $("body").attr("event_id");
            let eventSubeventId = null;
            if (typeof $("body").attr("event_subevent_id") !== typeof undefined && $("body").attr("event_subevent_id") !== false) {
                eventSubeventId = $("body").attr("event_subevent_id");
            }
            let firstDayOfMonth = $('#start-date').val();
            let lastDayOfMonth = $('#end-date').val();
            let period = 'hour';
            let numberOfCustomersId = $('#key-figures-number-of-sold-tickets');
            let startDateId = $('#start-date');
            let endDateId = $('#end-date');
            let periodHourId = $('#period-hour');
            let periodDayId = $('#period-day');
            let eventStatistic = $('.event-statistic');
            let params = {
                event_id: eventId,
                event_subevent_id: eventSubeventId,
                start: firstDayOfMonth,
                end: lastDayOfMonth,
                period: period
            };

            ajaxSubmitParamsAndGetNumberOfCustomers(params);

            if (periodHourId.prop("checked") === false) {
                periodHourId.prop("checked", true);
            }

            startDateId.change(function () {
                disablePeriodHour();
            });

            endDateId.change(function () {
                disablePeriodHour();
            });

            eventStatistic.change(function () {
                $("#event_statistic_form").submit();
            });

            $('[data-toggle="tooltip"]').tooltip();

            $(document).on("submit", "#event_statistic_form", function (e) {
                e.preventDefault();
                ajaxSubmitParamsAndGetNumberOfCustomers($(this).serialize());
            });


            function ajaxSubmitParamsAndGetNumberOfCustomers(params) {

                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_event_entered_customers/statistic",
                    data: params
                })
                    .done(function (data) {
                        let numberOfCustomers = data.number_of_entered_customers;
                        numberOfCustomersId.text(numberOfCustomers);
                        lineChart(data);
                        barChart(data);
                    })
                    .fail(function (data) {

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                    })

            }


            function lineChart(primalData) {

                let cumulativeData = [0];
                primalData.customer.forEach(function (elementToAdd, index) {
                    let newElement = cumulativeData[index] + elementToAdd;
                    cumulativeData.push(newElement);
                });
                cumulativeData.shift();

                Highcharts.chart('event-line-graph', {
                    chart: {type: 'area'},
                    exporting: {
                        buttons: {
                            contextButton: {
                                menuItems: [
                                    'viewFullscreen',
                                    'printChart',
                                    'separator',
                                    'downloadPNG',
                                    'downloadJPEG',
                                    'downloadPDF',
                                    'downloadSVG',
                                    'separator',
                                    'downloadCSV',
                                    'downloadXLS'
                                ]
                            }
                        }
                    },
                    title: false,
                    xAxis: {
                        categories: primalData.date,
                        title: {text: $("#event-line-graph").attr("data-x-axis-text")}
                    },
                    yAxis: {
                        title: {text: $("#event-line-graph").attr("data-y-axis-accumulated-text")}
                    },
                    credits: {enabled: false},
                    series: [{
                        showInLegend: false,
                        data: cumulativeData,
                        name: $("#event-line-graph").attr("data-y-axis-accumulated-text")
                    }]
                });
            }


            function barChart(primalData) {

                Highcharts.chart('event-bar-graph', {
                    chart: {type: 'column'},
                    exporting: {
                        buttons: {
                            contextButton: {
                                menuItems: [
                                    'viewFullscreen',
                                    'printChart',
                                    'separator',
                                    'downloadPNG',
                                    'downloadJPEG',
                                    'downloadPDF',
                                    'downloadSVG',
                                    'separator',
                                    'downloadCSV',
                                    'downloadXLS'
                                ]
                            }
                        }
                    },
                    title: false,
                    xAxis: {
                        categories: primalData.date,
                        title: {text: $("#event-bar-graph").attr("data-x-axis-text")}
                    },
                    yAxis: {
                        title: {text: $("#event-bar-graph").attr("data-y-axis-text")}
                    },
                    credits: {enabled: false},
                    series: [{
                        showInLegend: false,
                        data: primalData.customer,
                        name: $("#event-bar-graph").attr("data-y-axis-text")
                    }]
                });
            }


            function disablePeriodHour() {
                if (startDateId.val().length !== 0 &&
                    endDateId.val().length !== 0 &&
                    (Date.parse(endDateId.val()) - Date.parse(startDateId.val())) < (172800 * 1000)) {
                    $(periodHourId).prop("disabled", false);
                } else {
                    if (periodHourId.prop("checked") === true) {
                        periodDayId.prop("checked", true);
                    }
                    $(periodHourId).prop("disabled", true);
                }
            }

        }

    });
