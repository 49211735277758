$(document).ready(
    function () {

        let p = $("body").attr("p");
        if (p == 'organizer_events') {

            let event_copy_ajax_lock = false;

            $(document).on("click", ".event-copy-btn", function () {

                let event_id = $(this).attr("data-event-id");

                if (!event_copy_ajax_lock) {

                    event_copy_ajax_lock = true;

                    $.ajax({
                        type: 'POST',
                        url: "/ajax/organizer_event/copy",
                        data: {
                            event_id: event_id
                        }
                    })
                        .done(function (data) {

                            if (data.event_id !== undefined && data.event_id !== null) {
                                popupAlert();
                                setTimeout(function () {
                                    document.location.href = "/organizer/event/" + data.event_id + "/update";
                                }, 1500);
                            }

                        })
                        .fail(function (data) {

                            if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                                document.location.href = data.responseJSON.redirect_url;
                            }

                            event_copy_ajax_lock = false;

                        })
                        .always(function () {
                            event_copy_ajax_lock = false;
                        });

                }

                return false;

            });

        }

    }
);