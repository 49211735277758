$(document).ready(
    function () {

        let eventQueueCountDown = $('#queue-count-down-timer');
        let expirationTime = parseInt(eventQueueCountDown.data("event-queue-remaining-time")) * 1000;
        let expirationText = eventQueueCountDown.data("event-queue-time-text");

        if (expirationTime > 0) {
            if ($('#queue-count-down').hasClass('d-none')) {
                $('#queue-count-down').removeClass('d-none');
            }
            if ($('#queue-count-down-message').hasClass('d-none')) {
                $('#queue-count-down-message').removeClass('d-none');
            }
            if ($('.event_sale_start').hasClass('d-none')) {
                $('.event_sale_start').removeClass('d-none');
            }
        }

        eventQueueCountDownTimer(expirationTime, eventQueueCountDown, expirationText);

        function eventQueueCountDownTimer() {

            let remainingTime = expirationTime;

            let timer = setInterval(function () {

                remainingTime = remainingTime - 1000;
                let hours = Math.floor((remainingTime % (1000 * 60 * 60 * 60)) / (1000 * 60 * 60));
                let minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
                let seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
                if (hours >= 1) {
                    minutes = ('0' + minutes).slice(-2);
                }
                if (minutes >= 1) {
                    seconds = ('0' + seconds).slice(-2);
                }
                if (hours > 0) {
                    eventQueueCountDown.text(expirationText + hours + ":" + minutes + ":" + seconds);
                }
                if (!hours && minutes > 0) {
                    eventQueueCountDown.text(expirationText + minutes + ":" + seconds);
                }
                if (!hours && !minutes) {
                    eventQueueCountDown.text(expirationText + seconds);
                }

                if (remainingTime <= 1) {
                    $('#queue-count-down-timer').hide();
                    $('#queue-count-down-message').hide();
                    $('.event_sale_start').hide();
                }

                if (remainingTime <= 0) {
                    $('#countdown-next-button').prop("disabled", false);
                    clearInterval(timer);
                }

            }, 1000);

            return timer;

        }

    }
);

