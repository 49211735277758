$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p == 'organizer_event_ticket_category' && a == 'new') {

            function addMaxPurchaseQty() {
                if ($('#max_purchase_qty').val() == '' && typeof ($('#max_tickets').val() * 1) == 'number' && ($('#max_tickets').val() * 1) > 0) {
                    let maxPurchaseQty = $('#max_purchase_qty').attr('max') * 1;
                    if (typeof maxPurchaseQty == 'number' && $('#max_tickets').val() >= maxPurchaseQty) {
                        $('#max_purchase_qty').val(maxPurchaseQty);
                    } else {
                        $('#max_purchase_qty').val($('#max_tickets').val());
                    }
                }
            }

            $(document).on("focusout ", '#max_tickets', function () {
                addMaxPurchaseQty();
            });

            $(document.body).on('click', ".event-ticket-category-area-zone", function () {
                let max_tickets = 0;
                $('.event-ticket-category-area-zone').each(function () {
                    if ($(this).prop("checked") == true) {
                        max_tickets += $(this).attr('data-people-limit') * 1;
                    }
                });
                $('#max_tickets').val(max_tickets);
                addMaxPurchaseQty();
            });

        }

        let hasChangedOrganizerFee = false;
        let hasChangedPriceInclVatAndFee = false;
        calculatePriceInclVatAndFee();

        $(document).on("change", "input[name='ticket_price_ex_vat']", function () {
            $('.alert').remove();
            calculatePriceInclVatAndFee();
            hasChangedPriceInclVatAndFee = false;
        });
        $(document).on("change", "select[name='vat_code']", function () {
            $('.alert').remove();
            if (hasChangedPriceInclVatAndFee === true) {
                calculatePriceExVatAndFee();
            } else {
                calculatePriceInclVatAndFee();
            }

        });
        $(document).on("change", "input[name='organizer_fee']", function () {
            $('.alert').remove();
            let fee = Number($("input[name='organizer_fee']").val());
            hasChangedOrganizerFee = true;
            if (hasChangedPriceInclVatAndFee === true) {
                calculatePriceExVatAndFee(fee);
            } else {
                calculatePriceInclVatAndFee(fee);
            }

        });
        $(document).on("change", "input[name='ticket_price_incl_vat_and_fee']", function () {
            $('.alert').remove();
            hasChangedPriceInclVatAndFee = true;
            calculatePriceExVatAndFee();
        });

        function calculatePriceInclVatAndFee(fee = null) {
            let ticketPriceExVat = Number($("input[name='ticket_price_ex_vat']").val());
            updateFeeFromTicketPriceExVat(ticketPriceExVat);
            fee = Number($("input[name='organizer_fee']").val());
            let vatPercent = Number($("select[name='vat_code'] option:selected").text());
            let ticketPriceInclVatAndFee = Number((ticketPriceExVat + fee) * (1 + (vatPercent / 100))).toFixed(2);
            $("input[name='ticket_price_incl_vat_and_fee']").val(ticketPriceInclVatAndFee);
        }

        function calculatePriceExVatAndFee(fee = null) {
            let ticketPriceInclVatAndFee = Number($("input[name='ticket_price_incl_vat_and_fee']").val());
            let vatPercent = Number($("select[name='vat_code'] option:selected").text());
            if (isPriceAndFeeAllowed() === false) {
                let errorMessage = $("input[name='organizer_fee']").attr('data-error-message');
                appendAlert('#event-ticket-category-price-wrapper', errorMessage, 'danger', null, '#event_ticket_category_form');
                return false;
            }
            updateFeeFromTicketPriceInclVatAndFee(ticketPriceInclVatAndFee, vatPercent);
            fee = Number($("input[name='organizer_fee']").val());
            let ticketPriceExVat = Number((ticketPriceInclVatAndFee / (1 + (vatPercent / 100))) - fee);
            $("input[name='ticket_price_ex_vat']").val(ticketPriceExVat);
        }

        function updateFeeFromTicketPriceExVat(ticketPriceExVat) {
            let currentFee = $("input[name='organizer_fee']").val();
            let fees = getStandardFees();
            let fee = 0;
            let fee_amount = 0;
            $.each(fees, function (key, value) {
                if (ticketPriceExVat >= value.amount && value.amount >= fee_amount) {
                    fee_amount = value.amount;
                    if (value.is_percent === false) {
                        fee = Number(value.fee);
                    }
                    if (value.is_percent === true) {
                        fee = Number(ticketPriceExVat * value.fee_percent / 100).toFixed(2);
                    }
                }
            });
            if (currentFee > fee) {
                hasChangedOrganizerFee = true;
            }
            if (hasChangedOrganizerFee === false || (hasChangedOrganizerFee === true && fee > currentFee)) {
                $("input[name='organizer_fee']").val(fee);
                hasChangedOrganizerFee = false;
            }
        }

        function updateFeeFromTicketPriceInclVatAndFee(ticketPriceInclVatAndFee, vatPercent) {
            let fee = Number($("input[name='organizer_fee']").val());
            let ticketPriceExVat = Number(ticketPriceInclVatAndFee / (1 + (vatPercent / 100)) - fee);
            updateFeeFromTicketPriceExVat(ticketPriceExVat);
        }

        function isPriceAndFeeAllowed() {
            let ticketPriceInclVatAndFee = Number($("input[name='ticket_price_incl_vat_and_fee']").val());
            let vatPercent = Number($("select[name='vat_code'] option:selected").text());
            let fee = Number($("input[name='organizer_fee']").val());
            let ticketPriceExVat = Number((ticketPriceInclVatAndFee / (1 + (vatPercent / 100))) - fee);
            let fees = getStandardFees();
            let currentFee = 0;
            $.each(fees, function (key, value) {
                if (ticketPriceExVat > Number(value.amount)) {
                    if (value.is_percent === false) {
                        currentFee = Number(value.fee);
                    } else {
                        currentFee = Number(ticketPriceExVat * value.fee_percent / 100).toFixed(2);
                    }
                    if (currentFee > fee) {
                        fee = currentFee;
                    }
                    if (hasChangedOrganizerFee === false && fee > currentFee) {
                        fee = currentFee;
                    }
                }
            });

            if (((ticketPriceExVat + fee) * (1 + (vatPercent / 100))) > ticketPriceInclVatAndFee) {
                return false;
            }
            $("input[name='organizer_fee']").val(fee);
            return true;
        }

        function getStandardFees() {
            let fees = [
                {
                    fee: $("input[name='organizer_fee']").data('fee-level-0'),
                    amount: 0,
                    is_percent: false
                },
                {
                    fee: $("input[name='organizer_fee']").data('fee-level-1'),
                    amount: 1,
                    is_percent: false
                },
                {
                    fee: $("input[name='organizer_fee']").data('fee-level-2'),
                    amount: 51,
                    is_percent: false
                },
                {
                    fee: $("input[name='organizer_fee']").data('fee-level-3'),
                    amount: 101,
                    is_percent: false
                },
                {
                    fee: $("input[name='organizer_fee']").data('fee-level-4'),
                    amount: 251,
                    is_percent: false
                },
                {
                    fee: $("input[name='organizer_fee']").data('fee-level-5'),
                    amount: 451,
                    is_percent: false
                },
                {
                    fee: $("input[name='organizer_fee']").data('fee-level-6'),
                    amount: 701,
                    is_percent: false
                },
                {
                    fee: $("input[name='organizer_fee']").data('fee-level-7'),
                    amount: 951,
                    is_percent: false
                },
                {
                    fee_percent: $("input[name='organizer_fee']").data('fee-level-percent'),
                    amount: 1201,
                    is_percent: true
                }
            ];
            let specialOrganizer = 6;
            let organizerId = parseInt($("input[name='organizer_fee']").data('organizer-id'));
            if (specialOrganizer == organizerId){
                fees.pop();
                let specialOrganizerFee = {
                    fee: $("input[name='organizer_fee']").data('fee-level-percent'),
                    amount: 1201,
                    is_percent: false
                };
                fees.push(specialOrganizerFee);
            }
            return fees;
        }

        $(document).on("change", "input[name='multivalidation_ticket']", function () {
            if ($(this).is(":checked") && $('#multivalidation-ticket-wrapper').hasClass('d-none')) {
                $('#max_validations').val(1);
                $('#validation_days_after_end').val(0);
                $('#multivalidation-ticket-wrapper').removeClass('d-none')
            }
            if (!$(this).is(":checked") && !$('#multivalidation-ticket-wrapper').hasClass('d-none')) {
                $('#max_validations').val(1);
                $('#validation_days_after_end').val(0);
                $('#multivalidation-ticket-wrapper').addClass('d-none')
            }
        });


    });