$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p === 'visit_tracker_location' && a === 'search_all_locations') {

            let visit_tracker_location_search_ajax_lock = false;

            $(document).on("submit", "#visit_tracker_visitor_search_form", function (e) {

                e.preventDefault();

                if (!visit_tracker_location_search_ajax_lock) {

                    visit_tracker_location_search_ajax_lock = true;

                    $.ajax({
                        type: 'POST',
                        url: "/ajax/visit_tracker_location/search_all_locations",
                        data: {
                            serialized_post: $(this).serialize()
                        }
                    })
                        .done(function (data) {

                            if (data.html !== undefined && data.html !== null) {
                                $('#visit-tracker-visitor-list-all-location').empty();
                                $('#visit-tracker-visitor-list-all-location').append(data.html);
                            }

                            appendSearchParamsToExportButtons();

                        })
                        .fail(function (data) {

                            if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                                document.location.href = data.responseJSON.redirect_url;
                            }

                            visit_tracker_location_search_ajax_lock = false;

                        })
                        .always(function () {
                            visit_tracker_location_search_ajax_lock = false;
                        });

                }

                return false;

            });

            function appendSearchParamsToExportButtons() {
                let searchString = $('#search_string').val();
                let start = $('#start_date').val() + ' ' + $('#start_time').val() + ':00';
                let end = $('#end_date').val() + ' ' + $('#end_time').val() + ':59';
                $('.visitor-export-csv').each(function () {
                    let url = $(this).attr('href');
                    url += '&search=' + searchString + '&start=' + start + '&end=' + end + '&type=csv';
                    $(this).attr('href', url);
                });
                $('.visitor-export-pdf').each(function () {
                    let url = $(this).attr('href');
                    url += '&search=' + searchString + '&start=' + start + '&end=' + end + '&type=pdf';
                    $(this).attr('href', url);
                });
            }

        }

    }
);