$(document).ready(
    function () {

        let p = $("body").attr("p");

        if (p == 'organizer_event_entered_customers' || p == 'organizer_event_subevent_entered_customers') {

            let order_by = 'o.reg_date';
            let order_by_direction = 'desc';

            $(document).on("click", ".sort", function (e) {

                e.preventDefault();

                let page = 1;
                let search_string = $("#search_string").val();
                let event_id = $("#event_id").val();
                let event_ticket_category_id = $("#event_ticket_category_id").val();
                order_by = $(this).attr('id');
                order_by_direction = $(this).attr('data-order-direction');
                let event_subevent_id = null;
                if ($("#event_subevent_id").length) {
                    event_subevent_id = $("#event_subevent_id").val();
                }

                getEventCustomerList(page, event_id, event_ticket_category_id, search_string, order_by, order_by_direction, event_subevent_id);

            });

            $(document).on("click", ".pagination a", function (e) {

                e.preventDefault();

                let page = parseInt($(this).attr("href").substring(6));
                let search_string = $("#search_string").val();
                let event_id = $("#event_id").val();
                let event_ticket_category_id = $("#event_ticket_category_id").val();
                let event_subevent_id = null;
                if ($("#event_subevent_id").length) {
                    event_subevent_id = $("#event_subevent_id").val();
                }

                getEventCustomerList(page, event_id, event_ticket_category_id, search_string, order_by, order_by_direction, event_subevent_id);

            });

        }


        function getEventCustomerList(page = 1, event_id, event_ticket_category_id = null, search_string = '', order_by = null, order_by_direction = 'asc', event_subevent_id = null) {

            $.ajax({
                type: 'POST',
                url: "/ajax/organizer_event_entered_customers/search",
                data: {
                    page,
                    search_string,
                    event_id,
                    event_subevent_id,
                    event_ticket_category_id,
                    order_by,
                    order_by_direction
                }
            })
                .done(function (data) {

                    if (data.html !== undefined && data.html !== null) {
                        $("#ticket-list").remove();
                        $(".pagination").remove();
                        $("#ticket-list-search").after(data.html);
                    }

                    $('html,body').scrollTop(0);

                })
                .fail(function (data) {

                    if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                        document.location.href = data.responseJSON.redirect_url;
                    }

                })
        }

    }
);

