$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p === 'admin_article' && a === 'update') {

            let article_update_ajax_lock = false;

            $(document).on("submit", "#article-registration-form", function (e) {

                e.preventDefault();

                if (!article_update_ajax_lock) {

                    article_update_ajax_lock = true;

                    let editor = tinymce.get('article-tinymce');
                    let formData = new FormData(document.getElementById('article-registration-form'));
                    formData.append('article', editor.getContent());

                    /*let title = $("#title").val();
                    let article_id = $("#article_id").val();
                    let article_page_id = $("#article_page_id option:selected").val();
                    let status = $("#status").val();
                    let delete_image = $("#delete_image").val();
                    let image = $("#image").val();
                    let editor = tinymce.get('article-tinymce');
                    let article = editor.getContent();*/

                    $.ajax({
                        type: 'POST',
                        url: "/ajax/admin_article/update",
                        data: formData,
                        enctype: 'multipart/form-data',
                        async: true,
                        cache: false,
                        processData: false,
                        contentType: false
                        /* data: {
                             title: title,
                             article_id: article_id,
                             article_page_id: article_page_id,
                             status: status,
                             delete_image: delete_image,
                             image: image,
                             article: article
                         }*/
                    })
                        .done(function (data) {

                            if (data.message !== undefined && data.message !== null) {
                                prependAlert('#div_article_register_button', data.message, 'success', null, '#article-registration-form')
                            }
                            setTimeout(function () {
                                document.location.href = "/admin/article";
                            }, 1500);

                        })
                        .fail(function (data) {

                            if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url != null) {
                                document.location.href = data.responseJSON.redirect_url;
                            }

                            if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                                prependAlert('#div_article_register_button', data.responseJSON.error_message, 'danger', null, '#article-registration-form')
                            }

                            article_update_ajax_lock = false;

                        })
                        .always(function () {
                            article_update_ajax_lock = false;
                        });

                }

            });

        }

    }
);