$(document).ready(
    function () {

        $(document).on("change", ".user-role", function () {

            var new_role = $(this).children("option:selected").val();
            var current_role = $(this).attr("data-current-role");
            var user_id = $(this).attr("data-user-id");

            $.ajax({
                type: 'POST',
                url: "/ajax/organizer_settings_users/set_role",
                data: {
                    user_id: user_id,
                    role: new_role
                }
            })
                .done(function (data) {

                    if (data === true){
                        $("#new-role-" + user_id).attr("data-current-role", new_role);
                        popupAlert();
                    }

                })
                .fail(function (data) {

                    if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                        document.location.href = data.responseJSON.redirect_url;
                    }

                    if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                        $("#new-role-" + user_id).val(current_role);
                        inserAfterAlertTr('#user-' + user_id, data.responseJSON.error_message, 'danger');
                    }

                });

            return false;

        });
    }
);