$(document).ready(
    function () {

        $(document).on("click", ".event-subevent-deviation-cancel", function () {

            let eventSubeventDeviationId = $(this).data("event-subevent-deviation-id");
            let confirmMessage = $(this).data("confirm-message");

            if (!confirm(confirmMessage)) {
                return false;
            }

            $.ajax({
                type: 'POST',
                url: "/ajax/organizer_event_subevent/cancel_deviation",
                data: {
                    event_subevent_deviation_id: eventSubeventDeviationId
                }
            })
                .done(function (data) {

                    if (data == true) {
                        popupAlert();

                        $("#subevent-deviation-" + eventSubeventDeviationId).fadeOut(1000);
                        setTimeout(function () {
                            document.location.reload();
                        }, 1000);
                    }

                })
                .fail(function (data) {

                    if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                        document.location.href = data.responseJSON.redirect_url;
                    }

                    if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                        $("#subevent-deviation-error").remove();
                        $('#subevent-deviation-' + eventSubeventDeviationId).after('<tr><td colspan="3" id="subevent-deviation-error"></td></tr>');
                        appendAlert('#subevent-deviation-error', data.responseJSON.error_message, 'danger', null, '#subevent-deviation-table');
                    }

                });

            return false;

        });

        $(document).on("click", ".close", function () {
            $("#subevent-deviation-error").parents('tr').remove();
        });

    }
);