$(document).ready(
    function () {

        let p = $("body").attr("p");

        if (p === 'organizer_event_sales_tickets') {

            let eventId = $("body").attr("event_id");
            let startDate = $('#start-date').val();
            let orderOrigin = $("#order_origin").children("option:selected").val();
            let startDateId = $('#start-date');
            let eventStatistic = $('.event-statistic');

            if ($("body").attr("start_date") !== undefined) {
                startDate = $("body").attr("start_date");
                startDateId.val(startDate);
            }
            if ($("body").attr("order_origin") !== undefined) {
                orderOrigin = $("body").attr("order_origin");
                $("#order_origin").val(orderOrigin);
            }

            let $startDateSubevent = $(".datepicker-start-date-subevent").pickadate({
                format: 'yyyy-mm-dd',
                formatSubmit: 'yyyy-mm-dd',
                editable: true
            });

            let pickerStartDateSubevent = $startDateSubevent.pickadate('picker');

            $(document).on("click", ".datepicker-start-date-subevent", function () {
                pickerStartDateSubevent.open();
            });

            let params = {
                event_id: eventId,
                start: startDate,
                order_origin: orderOrigin,
                dates: ''
            };
            params = getParams(eventId, startDate, orderOrigin);
            ajaxSubmitParamsAndGetEventSalesInformation(params);

            eventStatistic.change(function () {
                startDate = $('#start-date').val();
                orderOrigin = $("#order_origin").children("option:selected").val();
                let dates = '';
                if ($(this).is($('#previous-dates'))) {
                    dates = 'previous';
                }
                if ($(this).is($('#next-dates'))) {
                    dates = 'next';
                }
                params = getParams(eventId, startDate, orderOrigin, dates);
                ajaxSubmitParamsAndGetEventSalesInformation(params);
            });


            $(document).on("click", ".statistic-arrow", function (e) {
                e.preventDefault();
                startDate = $('#start-date').val();
                orderOrigin = $("#order_origin").children("option:selected").val();
                let dates = '';
                if ($(this).is($('#previous-dates'))) {
                    dates = 'previous';
                }
                if ($(this).is($('#next-dates'))) {
                    dates = 'next';
                }
                params = getParams(eventId, startDate, orderOrigin, dates);
                ajaxSubmitParamsAndGetEventSalesInformation(params);
            });


            function ajaxSubmitParamsAndGetEventSalesInformation(params) {
                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_event_sales_tickets/sales",
                    data: params
                })
                    .done(function (data) {
                        $('#start-date').val(data.start);
                        barChart(data);
                    })
                    .fail(function (data) {
                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }
                    })
            }


            function getParams(eventId, startDate, orderOrigin, dates = '') {
                params = {
                    event_id: eventId,
                    start: startDate,
                    order_origin: orderOrigin,
                    dates: dates
                };
                return params;
            }


            function barChart(primalData) {

                Highcharts.chart('event-bar-graph', {
                    chart: {type: 'column'},
                    exporting: {
                        buttons: {
                            contextButton: {
                                menuItems: [
                                    'viewFullscreen',
                                    'printChart',
                                    'separator',
                                    'downloadPNG',
                                    'downloadJPEG',
                                    'downloadPDF',
                                    'downloadSVG',
                                    'separator',
                                    'downloadCSV',
                                    'downloadXLS'
                                ]
                            }
                        }
                    },
                    title: false,
                    xAxis: {
                        categories: primalData.date,
                        title: {text: $("#event-bar-graph").attr("data-x-axis-text")}
                    },
                    yAxis: {
                        title: {text: $("#event-bar-graph").attr("data-y-axis-text")}
                    },
                    credits: {enabled: false},
                    series: [{
                        showInLegend: false,
                        data: primalData.ticket,
                        name: $("#event-bar-graph").attr("data-y-axis-text")
                    }]
                });
            }
        }

    });