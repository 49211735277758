$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p == 'organizer_event_form' && a == 'edit') {

            $(document).on("click ", '.event-form-answer-option-delete-button', function () {

                if (!$(this).data('new-event-form-answer-option')) {
                    let newAnswerOptionCheck = $(this).data('new-event-form-answer-option');
                    let confirmMessage = $(this).data('new-event-form-answer-option-message');
                    console.log(newAnswerOptionCheck);
                    if (newAnswerOptionCheck == false) {
                        if (!confirm(confirmMessage)) {
                            return false;
                        }
                    }
                }

                $(this).closest('div.form-row').remove();
            });
        }
    });