$(document).ready(
    function () {

        let p = $("body").attr("p");

        if (p == 'organizer_event_customers' || p == 'organizer_event_subevent_customers') {

            $(document).on("click", ".ticket-refund", function (e) {

                e.preventDefault();
                let confirm_message = $("#ticket-list").attr("data-order-refund-message");
                let refundButton = $(this);
                if (!confirm(confirm_message)) {
                    return false;
                }
                $(".alert-danger").remove();
                refundOrder(refundButton);

            });

        }

        function refundOrder(refundButton) {

            let ticketId = refundButton.attr('data-ticket-refund');
            let orderReference = refundButton.attr('data-order-reference');
            let ticketStatusRefunded = $('#ticket-list').attr('data-ticket-status-refunded');
            let ticketStatusRefundedMessage = $('#ticket-list').attr('data-ticket-status-refunded-message');

            $.ajax({
                type: 'POST',
                url: "/ajax/organizer_event_customers/refund",
                data: {
                    ticket_id: ticketId
                }
            })
                .done(function (data) {


                    if (data.message === true) {

                        $('.ticket-status-' + orderReference).each(function () {
                            $(this).text(ticketStatusRefunded);
                        });

                        $('.modal-footer-' + orderReference).each(function () {
                            prependAlert($(this), ticketStatusRefundedMessage, 'success', null, '#modal-footer')
                        });

                        $('.ticket-send-' + orderReference).each(function () {
                            $(this).remove();
                        });

                        $('.ticket-download-' + orderReference).each(function () {
                            $(this).remove();
                        });

                        $('.ticket-deactivate-' + orderReference).each(function () {
                            $(this).remove();
                        });

                        $('.ticket-refund-' + orderReference).each(function () {
                            $(this).remove();
                        });

                        $('.ticket-information-' + orderReference).each(function () {
                            $(this).remove();
                        });

                        $('.order-part-refund-order-reference-' + orderReference).each(function () {
                            $(this).remove();
                        });

                        $('.order-part-refund-button-order-reference-' + orderReference).each(function () {
                            $(this).remove();
                        });

                    }

                    if (typeof data.message === 'string' && data.message !== 'true') {
                        prependAlert('#modal-footer-' + orderReference, data.message, 'danger', null, '#modal-footer')
                    }


                })
                .fail(function (data) {

                    if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                        document.location.href = data.responseJSON.redirect_url;
                    }

                })
        }

    }
);

