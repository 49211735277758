$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p == 'organizer_event' && a == 'update') {

            if ($("#event_id").val() !== undefined && $("#event_id").val() != null && $("#event_id").val() !== "") {

                let event_update_ajax_lock = false;

                $(document).on("submit", "#event_register_form", function () {

                    if (!event_update_ajax_lock) {

                        event_update_ajax_lock = true;

                        $(':disabled').each(function () {
                            $(this).removeAttr('disabled');
                        });

                        if ($('#area').prop("checked") === true) {
                            let isAreaZoneChecked = false;
                            let eventAreaZoneArray = $('.event-area-zone');
                            eventAreaZoneArray.each(function () {
                                if ($(this).prop("checked") === true) {
                                    isAreaZoneChecked = true;
                                }
                            });
                            if (isAreaZoneChecked === false) {
                                let missingZoneErrorMessage = $('#area_zone').attr('data-missing-zone-error-message');
                                prependAlert('#div_event_register_button', missingZoneErrorMessage, 'warning', null, '#event_register_form');
                                event_update_ajax_lock = false;
                                return false;
                            }
                        }

                        let formData = new FormData(document.getElementById('event_register_form'));

                        $.ajax({
                            type: 'POST',
                            url: "/ajax/organizer_event/update",
                            data: formData,
                            async: true,
                            cache: false,
                            processData: false,
                            contentType: false
                        })
                            .done(function (data) {

                                if (data.message !== undefined && data.message !== null) {

                                    if (data.max_tickets !== undefined && data.max_tickets !== null) {

                                        prependAlert('#div_event_register_button', data.message, 'warning', null, '#event_register_form');
                                        $("#max_tickets").val(data.max_tickets);

                                    } else {

                                        prependAlert('#div_event_register_button', data.message, 'success', null, '#event_register_form');
                                        $('.card-box-to-hide').hide();
                                        $('#event_register_button').hide();

                                        setTimeout(function () {
                                            document.location.href = "/organizer/events";
                                        }, 3000);
                                    }

                                }

                            })
                            .fail(function (data) {

                                if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                                    document.location.href = data.responseJSON.redirect_url;
                                }

                                if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                                    prependAlert('#div_event_register_button', data.responseJSON.error_message, 'danger', null, '#event_register_form');
                                }

                                event_update_ajax_lock = false;

                            })
                            .always(function () {
                                event_update_ajax_lock = false;
                            });

                    }

                    return false;

                });

            }

        }

    }
);