$(document).ready(
    function () {

        let p = $("body").attr("p");
        if (p === 'admin_dashboard') {

            let firstDayOfMonth = $('#start-date').val();
            let lastDayOfMonth = $('#end-date').val();
            let period = 'week';
            let orderOrigin = $("#order_origin").children("option:selected").val();
            let revenue = $('#key-figures-revenue').data('revenue-standard');
            let numberOfEventsId = $('#key-figures-number-of-events');
            let numberOfSoldTicketsId = $('#key-figures-number-of-sold-tickets');
            let revenueFromOrderFeesId = $('#key-figures-revenue');
            let startDateId = $('#start-date');
            let endDateId = $('#end-date');
            let periodHourId = $('#period-hour');
            let periodWeekId = $('#period-week');
            let adminStatistic = $('.admin-dashboard-statistic');
            let params = {
                start: firstDayOfMonth,
                end: lastDayOfMonth,
                period: period,
                revenue: revenue,
                order_origin: orderOrigin
            };

            ajaxSubmitParamsAndGetAdminDashboardSalesInformation(params);

            if (periodWeekId.prop("checked") === false) {
                periodWeekId.prop("checked", true);
            }

            startDateId.change(function () {
                disablePeriodHour();
            });

            endDateId.change(function () {
                disablePeriodHour();
            });

            adminStatistic.change(function () {
                $("#admin_dashboard_form").submit();
            });

            $(document).on("click", ".admin-dashboard-revenue-link", function () {
                params = {
                    start: startDateId.val(),
                    end: endDateId.val(),
                    period: $("input[name='period']:checked").val(),
                    organizer_id: $("#organizer_id option:selected").val(),
                    revenue: $(this).data('revenue'),
                    order_origin: $("select[name='order_origin']").val()
                };
                $("#key-figures-revenue-text").text($(this).data('revenue-text'));
                ajaxSubmitParamsAndGetAdminDashboardSalesInformation(params);
                return false;
            });

            $('[data-toggle="tooltip"]').tooltip();

            $(document).on("submit", "#admin_dashboard_form", function () {
                ajaxSubmitParamsAndGetAdminDashboardSalesInformation($(this).serialize() + '&revenue=' + params.revenue);
                return false;
            });


            function ajaxSubmitParamsAndGetAdminDashboardSalesInformation(params) {

                $.ajax({
                    type: 'POST',
                    url: "/ajax/admin_dashboard/sales",
                    data: params
                })
                    .done(function (data) {
                        let numberOfEventss = data.number_of_events;
                        let numberOfSoldTickets = data.number_of_sold_tickets;
                        let revenueFromOrderFees = data.revenue_sum;
                        numberOfEventsId.text(numberOfEventss);
                        if (data.number_of_refunded_tickets !== undefined && data.number_of_refunded_tickets != null) {
                            numberOfSoldTicketsId.text(numberOfSoldTickets + ' (' + data.number_of_refunded_tickets + ')');
                        } else {
                            numberOfSoldTicketsId.text(numberOfSoldTickets);
                        }
                        revenueFromOrderFeesId.text("kr " + parseFloat(revenueFromOrderFees).format(2, 3, ' ', ','));
                        lineChart(data);
                        barChart(data);
                    })
                    .fail(function (data) {

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                    })

            }


            function lineChart(primalData) {

                let cumulativeData = [0];
                primalData.revenue.forEach(function (elementToAdd, index) {
                    let newElement = cumulativeData[index] + elementToAdd;
                    cumulativeData.push(newElement);
                });
                cumulativeData.shift();

                Highcharts.chart('admin-dashboard-line-graph', {
                    chart: {type: 'area'},
                    exporting: {
                        buttons: {
                            contextButton: {
                                menuItems: [
                                    'viewFullscreen',
                                    'printChart',
                                    'separator',
                                    'downloadPNG',
                                    'downloadJPEG',
                                    'downloadPDF',
                                    'downloadSVG',
                                    'separator',
                                    'downloadCSV',
                                    'downloadXLS'
                                ]
                            }
                        }
                    },
                    title: false,
                    xAxis: {
                        categories: primalData.date,
                        title: {text: $("#admin-dashboard-line-graph").attr("data-x-axis-text")}
                    },
                    yAxis: {
                        title: {text: $("#admin-dashboard-line-graph").attr("data-y-axis-text")}
                    },
                    credits: {enabled: false},
                    series: [{
                        showInLegend: false,
                        data: cumulativeData,
                        name: $("#admin-dashboard-line-graph").attr("data-y-axis-text")
                    }]
                });
            }


            function barChart(primalData) {

                Highcharts.chart('admin-dashboard-bar-graph', {
                    chart: {type: 'column'},
                    exporting: {
                        buttons: {
                            contextButton: {
                                menuItems: [
                                    'viewFullscreen',
                                    'printChart',
                                    'separator',
                                    'downloadPNG',
                                    'downloadJPEG',
                                    'downloadPDF',
                                    'downloadSVG',
                                    'separator',
                                    'downloadCSV',
                                    'downloadXLS'
                                ]
                            }
                        }
                    },
                    title: false,
                    xAxis: {
                        categories: primalData.date,
                        title: {text: $("#admin-dashboard-bar-graph").attr("data-x-axis-text")}
                    },
                    yAxis: {
                        title: {text: $("#admin-dashboard-bar-graph").attr("data-y-axis-text")}
                    },
                    credits: {enabled: false},
                    series: [{
                        showInLegend: false,
                        data: primalData.revenue,
                        name: $("#admin-dashboard-bar-graph").attr("data-y-axis-text")
                    }]
                });
            }


            function disablePeriodHour() {
                if (startDateId.val().length !== 0 &&
                    endDateId.val().length !== 0 &&
                    (Date.parse(endDateId.val()) - Date.parse(startDateId.val())) < (172800 * 1000)) {
                    $(periodHourId).prop("disabled", false);
                } else {
                    if (periodHourId.prop("checked") === true) {
                        periodWeekId.prop("checked", true);
                    }
                    $(periodHourId).prop("disabled", true);
                }
            }

        }

    }
);
