$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p == 'organizer_event_form' && a == 'edit') {


            $(document).on("click ", '.add-event-form-answer-option-button', function () {
                let addAnswerButton = $(this);
                let event_form_question_index = $(this).data('event-form-question-index');
                let event_form_answer_option_index = parseInt($(this).attr('data-event-form-answer-option-index')) + 1
                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_event_form/add_answer",
                    data: {
                        event_form_question_index,
                        event_form_answer_option_index
                    }
                }).done(function (data) {
                    if (data.html !== undefined && data.html !== null) {
                        let eventFormAnswerOptionWrapper = addAnswerButton.closest('div.event-form-question').find('div.event-form-answer-option-wrapper');
                        eventFormAnswerOptionWrapper.append(data.html);
                        addAnswerButton.attr('data-event-form-answer-option-index', event_form_answer_option_index);

                        Sortable.create(eventFormAnswerOptionWrapper, {
                            handle: ".event-form-answer-option-reorder",
                            animation: 150,
                            sort: true,
                            delay: 0,
                            touchStartThreshold: 0,
                            disabled: false,
                            draggable: ".event-form-answer-option",
                            direction: "horizontal",
                            ghostClass: "sortable-ghost",
                            removeCloneOnHide: true,
                        });


                    }
                }).fail(function (data) {
                    if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                        document.location.href = data.responseJSON.redirect_url;
                    }
                });

            });
        }
    });