$(document).ready(
    function () {

        let p = $("body").attr("p");

        if (p == 'article') {

            let urlParams = new URLSearchParams(window.location.search);
            const page = urlParams.get('page');
            const article_page_id = $("body").attr("article_page_id");

            $(document).on("click", ".pagination a", function (e) {
                e.preventDefault();
                let page = parseInt($(this).attr("href").substring(6));
                getArticleList(page, article_page_id);
            });

        }


        function getArticleList(page = 1, article_page_id = null) {
            $.ajax({
                type: 'POST',
                url: "/ajax/article/search",
                data: {
                    page,
                    article_page_id
                }
            })
                .done(function (data) {

                    if (data.html !== undefined && data.html !== null) {
                        $("#article-list").remove();
                        $(".pagination").remove();
                        $("#breadcrumb").after(data.html);
                    }

                    $('html,body').scrollTop(0);

                })
                .fail(function (data) {

                    if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                        document.location.href = data.responseJSON.redirect_url;
                    }

                })
        }


    }
);

